<template>
  <el-dialog
    center
    width="800px"
    ref="form"
    title="大纲审核"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      label-position="top"
      ref="form"
      :model="formData"
      :rules="formRules"
    >
      <el-row :gutter="20">
        <el-form-item label="审核结果" prop="auditStatus">
          <el-radio-group v-model="formData.auditStatus">
            <el-radio label="同意">同意</el-radio>
            <el-radio label="不同意">不同意</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="审核意见" prop="auditOption">
          <el-input
            type="textarea"
            v-model="formData.auditOption"
            placeholder="请输入"
          />
        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { outlineAudit } from "@/api/outline";
import { role, status } from "@/utils/dic";
import { getRoleInfo } from "@/utils";
export default {
  /**
   * 业务id
   */
  props: {
    businessId: {
      type: String,
      default: "",
      required: true
    }
  },
  data() {
    return {
      visible: false,
      isEdit: false,
      formData: {},
      formRules: {
        auditStatus: [{ required: true, message: "请选择", trigger: "blur" }],
        auditOption: [
          { required: true, message: "请输入审批意见", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    open() {
      this.formData = {};
      this.visible = true;
    },
    // 提交
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.visible = false;
          this.outlineAudit();
        } else {
          this.$message.error("请填写必要字段");
          return false;
        }
      });
    },
    outlineAudit() {
      /**
       * 获取用户信息和角色信息
       */
      const roleInfo = getRoleInfo();
      let auditStatus = status.BO_HUI;
      if (this.formData.auditStatus === "同意") {
        switch (roleInfo.roleId) {
          case role.JIAO_DUI:
            auditStatus = status.BIAO_SHEN;
            break;
          case role.BIAO_SHEN:
            auditStatus = status.PI_ZHUN;
            break;
          case role.PI_ZHUN:
            auditStatus = status.YI_PI_ZHUN;
            break;
          default:
            auditStatus = "";
            break;
        }
      }
      let params = {
        businessId: this.businessId,
        auditStatus,
        auditOption: this.formData.auditOption
      };
      outlineAudit(params).then(res => {
        this.$message.success("操作成功");
        this.$parent.testOutlineList();
        console.log(res);
      });
    }
  }
};
</script>
