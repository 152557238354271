<template>
  <el-container class="app-wrapper">
    <Sidebar ref="Sidebar" />

    <el-container>
      <el-header style="text-align: right; font-size: 12px">
        <span>{{ userName }}</span>
        <el-dropdown style="height:30px;">
          <i class="el-icon-setting" style="margin-left: 15px" />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="logOut">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header>

      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// @ is an alias to /src
// import Logo from "./components/Logo.vue";
import Sidebar from "./components/Sidebar.vue";
import { setToken, getUserInfo } from "@/utils";

export default {
  name: "Main",
  components: { Sidebar },
  data() {
    return {
      userName: ""
    };
  },
  computed: {
    key() {
      return this.$route.path;
    }
  },
  mounted() {
    this.userName = getUserInfo().userName;
  },
  methods: {
    logOut() {
      setToken(null);
      const _this = this;
      _this.$router.push("/login");
    }
  }
};
</script>
<style lang="scss" scoped>
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.el-menu {
  border: none !important;
}
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
  .el-dropdown-menu {
    top: 30px !important;
  }
}
</style>
