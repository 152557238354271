import axios from "axios";
import { getToken } from "@/utils";
import { Message } from "element-ui";
import router from "@/router";
// axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
// axios.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded;charset=UTF-8";
var api = null;
if (process.env.NODE_ENV === "production") {
  api = window.baseUrl;
}
// create an axios instance
const service = axios.create({
  baseURL: api, // process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 600000 // request timeout
});
// request interceptor
service.interceptors.request.use(
  config => {
    // 每次发送请求之前判断v是否存在token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    var token = getToken();
    token && (config.headers.Authorization = token);
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;
    if (res.code !== 200) {
      if (res.msg) {
        Message({
          message: res.msg || "Error",
          type: "error",
          duration: 2 * 1000
        });
      }
      if (res.code === 401 || res.code === 502) {
        router.replace({ name: "login" });
      }
      return Promise.reject(res);
    } else {
      return res;
    }
  },
  error => {
    console.log("err" + error); // for debug
    return Promise.reject(error);
  }
);

export default service;
