<template>
  <el-pagination
    background
    style="float:right;"
    layout="total, prev, pager, next, jumper"
    :total="total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
</template>
<script>
export default {
  name: "Page",
  // 过滤器设计目的就是用于简单的文本转换
  filters: {},
  props: {
    total: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10
    }
  },
  computed: {},
  // 若要实现更复杂的数据变换，你应该使用计算属性
  watch: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    // 每页展示条数
    handleSizeChange(val) {
      // 事件传递
      this.$emit("handleSizeChangeSub", val);
    },
    // 当前页
    handleCurrentChange(val) {
      // 事件传递
      this.$emit("handleCurrentChangeSub", val);
    }
  }
};
</script>
