<template>
  <div id="root">
    <div class="header-area">
      <h1>试验实施</h1>
    </div>
    <div class="body-area">
      <el-form
        ref="form"
        label-position="top"
        label-width="80px"
        :model="formData"
        :rules="formRules"
      >
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>基本信息</span>
          </div>
          <el-row>
            <el-col :span="8">
              <el-form-item label="试验名称" prop="experimentName">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.experimentName"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item label="实际辐射源" prop="radiationSource">
              <el-select
                style="width:20%"
                multiple
                :disabled="handleType === 'find'"
                v-model="radiationSource"
                placeholder="请选择"
                @change="radiationSourceChange"
              >
                <el-option label="钴源" value="钴源" />
                <el-option label="电子加速器" value="电子加速器" />
                <el-option label="X光机" value="X光机" />
              </el-select>
              <Warn
                compare-type="arr"
                :handleType="handleType"
                :outlineVal="outlineData.radiationSource"
                :carryVal="formData.radiationSource"
                outlineLabel="辐射源"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="实际剂量率/注量率" prop="realityDoseRate">
              <el-input
                style="width:200px"
                :disabled="handleType === 'find'"
                v-model="formData.realityDoseRate"
                :maxlength="maxlength"
                show-word-limit
                placeholder="请输入"
              />
              <span class="remark">rad(Si)</span>
              <Warn
                :outlineVal="outlineData.radiationRequire2"
                :carryVal="formData.realityDoseRate"
                outlineLabel="实际剂量率/注量率"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="实际辐照环境温度" prop="realityCondition1">
              <el-input
                v-model="formData.realityCondition1"
                type="number"
                style="width:200px"
                placeholder="请输入温度"
              />
              <span class="remark">C°</span>
              <Warn
                compareType="range"
                :outlineVal="outlineData.radiationRequire4"
                :carryVal="formData.realityCondition1"
                outlineLabel="实际辐照环境温度"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="实际辐照环境湿度" prop="realityCondition1h">
              <el-input
                :disabled="handleType === 'find'"
                v-model="formData.realityCondition1h"
                type="number"
                style="width:200px"
                placeholder="请输入湿度"
              />
              <span class="remark">%RH</span>
              <Warn
                compareType="range"
                :outlineVal="outlineData.radiationRequire4h"
                :carryVal="formData.realityCondition1h"
                outlineLabel="实际辐照环境湿度"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="实际测试环境温度" prop="realityCondition2">
              <el-input
                v-model="formData.realityCondition2"
                type="number"
                style="width:200px"
                placeholder="请输入温度"
              />
              <span class="remark">C°</span>
              <Warn
                compareType="range"
                :outlineVal="outlineData.testCondition1"
                :carryVal="formData.realityCondition2"
                outlineLabel="实际测试环境温度"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="实际测试环境温度" prop="realityCondition2h">
              <el-input
                v-model="formData.realityCondition2h"
                type="number"
                style="width:200px"
                placeholder="请输入湿度"
              />
              <span class="remark">%RH</span>
              <Warn
                compareType="range"
                :outlineVal="outlineData.testCondition7"
                :carryVal="formData.realityCondition2h"
                outlineLabel="实际测试环境湿度"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="测试项目" prop="testProject">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.testProject"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
                <Warn
                  :outlineVal="outlineData.testProject"
                  :carryVal="formData.testProject"
                  outlineLabel="测试项目"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="测试数据保存位置" prop="testDataPosition">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.testDataPosition"
                  :maxlength="maxlength"
                  show-word-limit
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 10 }"
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="防静电措施" prop="electrostaticPrevention">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.electrostaticPrevention"
                  :maxlength="maxlength"
                  show-word-limit
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 10 }"
                  placeholder="请输入"
                />
                <Warn
                  :outlineVal="outlineData.testCondition2"
                  :carryVal="formData.electrostaticPrevention"
                  outlineLabel="防静电措施"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <el-row style="padding:20px 0;margin-bottom:10px;">
          <el-button
            type="primary"
            v-if="!isAddFuZhao"
            @click="isAddRecordHandle('isAddFuZhao')"
            >+添加辐照过程记录</el-button
          >
          <el-button
            type="danger"
            v-else
            @click="isAddRecordHandle('isAddFuZhao')"
            >取消辐照过程记录</el-button
          >
          <el-button
            type="primary"
            v-if="!isAddTuiHuo"
            @click="isAddRecordHandle('isAddTuiHuo')"
            >+添加退火过程记录</el-button
          >
          <el-button
            type="danger"
            v-else
            @click="isAddRecordHandle('isAddTuiHuo')"
            >取消退火过程记录</el-button
          >
          <el-button
            type="primary"
            v-if="!isAddCeShi"
            @click="isAddRecordHandle('isAddCeShi')"
            >+添加测试过程记录</el-button
          >
          <el-button
            type="danger"
            v-else
            @click="isAddRecordHandle('isAddCeShi')"
            >取消测试过程记录</el-button
          >
        </el-row>
        <el-card class="box-card" v-if="isAddFuZhao">
          <div slot="header" class="clearfix">
            <span>辐照过程</span>
            <el-button
              style="float:right"
              type="primary"
              @click="printRecord('辐照')"
              >打印</el-button
            >
          </div>

          <el-row>
            <el-form-item label="样品对应偏置条件" prop="radiationRecord1">
              <el-input
                style="width:30%"
                :disabled="handleType === 'find'"
                v-model="formData.radiationRecord1"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 10 }"
                placeholder="请输入"
                :maxlength="maxlength"
                show-word-limit
              />
              <Warn
                :outlineVal="outlineData.radiationRequire3"
                :carryVal="formData.radiationRecord1"
                outlineLabel="样品对应偏执条件"
              />
            </el-form-item>
          </el-row>
          <!-- <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                label="实际累积剂量点/注量点"
                prop="radiationRecord2"
              >
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.radiationRecord2"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
                <Warn
                  :outlineVal="outlineData.radiationSetting"
                  :carryVal="formData.radiationRecord2"
                  outlineLabel="实际累积剂量点/注量点"
                />
              </el-form-item>
            </el-col>
          </el-row> -->
          <!-- <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="辐照起止时间">
                <el-date-picker
                  value-format="yyyy-MM-dd hh:mm:ss"
                  :disabled="handleType === 'find'"
                  v-model="formData.radiationRecord3"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :unlink-panels="true"
                />
              </el-form-item>
            </el-col>
          </el-row> -->
          <!-- <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="降源后是否断电" prop="radiationRecord5">
                <el-radio-group
                  :disabled="handleType === 'find'"
                  v-model="formData.radiationRecord5"
                >
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row> -->
          <!-- <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="记录人" prop="radiationRecord6">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.radiationRecord6"
                  :maxlength="20"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="备注">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.radiationRecord7"
                  type="textarea"
                  :maxlength="maxlength"
                  show-word-limit
                  :autosize="{ minRows: 2, maxRows: 10 }"
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <el-form-item label="过程图1">
                <Upload v-model="formData.radiationProcessImg1" />
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="过程图2">
                <Upload v-model="formData.radiationProcessImg2" />
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="备注">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.radiationRecord8"
                  type="textarea"
                  :maxlength="maxlength"
                  show-word-limit
                  :autosize="{ minRows: 2, maxRows: 10 }"
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row v-if="sampleData.length">
            <span>样品信息</span>
            <SampleTable
              :disabled="handleType === 'find'"
              @inputBlur="saveSampleInfo"
              :tableData="sampleData"
            />
          </el-row>
          <el-row>
            <Record v-model="radiationTableData" recordType="辐照" />
          </el-row>
        </el-card>
        <el-card class="box-card" v-if="isAddTuiHuo">
          <div slot="header" class="clearfix">
            <span>退火过程记录</span>
            <el-button
              style="float:right"
              type="primary"
              @click="printRecord('退火')"
              >打印</el-button
            >
          </div>

          <el-row>
            <el-col :span="8">
              <el-form-item label="退火设备">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.annealingEquipment"
                  type="textarea"
                  :maxlength="maxlength"
                  show-word-limit
                  :autosize="{ minRows: 2, maxRows: 10 }"
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="退火时长">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.annealingTime"
                  type="textarea"
                  :maxlength="maxlength"
                  show-word-limit
                  :autosize="{ minRows: 2, maxRows: 10 }"
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="退火温度">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.annealingTemperature"
                  type="number"
                  style="width:200px"
                  placeholder="请输入温度"
                />
                <span class="remark">C°</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="环境温度">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.ambientTemperature"
                  type="number"
                  style="width:200px"
                  placeholder="请输入温度"
                />
                <span class="remark">C°</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="环境湿度">
                <el-input
                  v-model="formData.ambientHumidity"
                  type="number"
                  style="width:200px"
                  placeholder="请输入湿度"
                />
                <span class="remark">%RH</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="样品对应偏置条件" prop="annealRecord1">
                <el-input
                  style="width:30%"
                  :disabled="handleType === 'find'"
                  v-model="formData.annealRecord1"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
                <Warn
                  :outlineVal="formData.radiationRecord1"
                  :carryVal="formData.annealRecord1"
                  outlineLabel="样品对应偏置条件"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row :gutter="20">
            <el-form-item label="实际累积退火时长" prop="annealRecord2">
              <Hhmmss
                :disabled="handleType === 'find'"
                v-model="formData.annealRecord2"
              />
            </el-form-item>
          </el-row> -->
          <!-- <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="退火起止时间">
                <el-date-picker
                  value-format="yyyy-MM-dd hh:mm:ss"
                  :disabled="handleType === 'find'"
                  v-model="formData.annealRecord3"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :unlink-panels="false"
                />
              </el-form-item>
            </el-col>
          </el-row> -->
          <!-- <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="退火结束后是否断电" prop="annealRecord5">
                <el-radio-group
                  :disabled="handleType === 'find'"
                  v-model="formData.annealRecord5"
                >
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row> -->
          <!-- <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="记录人" prop="annealRecord6">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.annealRecord6"
                  :maxlength="20"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="备注">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.annealRecord7"
                  type="textarea"
                  :maxlength="maxlength"
                  show-word-limit
                  :autosize="{ minRows: 2, maxRows: 10 }"
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <!-- <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="备注">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.annealRecord8"
                  type="textarea"
                  :maxlength="maxlength"
                  show-word-limit
                  :autosize="{ minRows: 2, maxRows: 10 }"
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row>
            <el-col :span="4">
              <el-form-item label="过程图1">
                <Upload v-model="formData.annealingProcessImg1" />
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="过程图2">
                <Upload v-model="formData.annealingProcessImg2" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="sampleData.length">
            <span>样品信息</span>
            <SampleTable
              :disabled="handleType === 'find'"
              @inputBlur="saveSampleInfo"
              :tableData="sampleData"
            />
          </el-row>
          <el-row>
            <Record v-model="annealTableData" recordType="退火" />
          </el-row>
        </el-card>
        <el-card class="box-card" v-if="isAddCeShi">
          <div slot="header" class="clearfix">
            <span>测试过程记录</span>
            <el-button
              style="float:right"
              type="primary"
              @click="printRecord('测试')"
              >打印</el-button
            >
          </div>
          <!-- <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="实际累积剂量点/注量点" prop="testRecord1">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.testRecord1"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row> -->
          <!-- <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="测试起止时间">
                <el-date-picker
                  value-format="yyyy-MM-dd hh:mm:ss"
                  :disabled="handleType === 'find'"
                  v-model="formData.testRecord3"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :unlink-panels="true"
                />
              </el-form-item>
            </el-col>
          </el-row> -->
          <!-- <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="测试结果" prop="testRecord4">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.testRecord4"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 10 }"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row> -->
          <!-- <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="记录人" prop="testRecord5">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.testRecord5"
                  :maxlength="20"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="备注">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.testRecord6"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 10 }"
                  placeholder="请输入"
                  :maxlength="maxlength"
                  show-word-limit
                />
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="备注">
                <el-input
                  :disabled="handleType === 'find'"
                  v-model="formData.testRecord7"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 10 }"
                  placeholder="请输入"
                  :maxlength="maxlength"
                  show-word-limit
                />
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row>
            <el-col :span="4">
              <el-form-item label="过程图1">
                <Upload v-model="formData.testingProcessImg1" />
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="过程图2">
                <Upload v-model="formData.testingProcessImg2" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="sampleData.length">
            <span>样品信息</span>
            <SampleTable
              :disabled="handleType === 'find'"
              @inputBlur="saveSampleInfo"
              :tableData="sampleData"
            />
          </el-row>
          <el-row>
            <Record v-model="testTableData" recordType="测试" />
          </el-row>
        </el-card>
      </el-form>
      <div class="footer">
        <div class="footer-btn">
          <el-button
            v-if="handleType !== 'find'"
            size="medium"
            type="primary"
            @click="submit"
            :loading="btnLoading"
            >提交</el-button
          >
          <el-button @click="toBack" size="medium">取消</el-button>
        </div>
      </div>
    </div>

    <!-- 打印弹窗 -->
    <AnnealPrint
      :carryData="formData"
      :outlineData="outlineData"
      :records="annealTableData"
      :visible.sync="annealPrintvisible"
    />
    <RadiationPrint
      :carryData="formData"
      :outlineData="outlineData"
      :records="radiationTableData"
      :visible.sync="radiationPrintvisible"
    />
    <TestPrint
      :carryData="formData"
      :outlineData="outlineData"
      :records="testTableData"
      :sampleData="sampleData"
      :visible.sync="testPrintvisible"
    />
  </div>
</template>

<script>
import { outLineSave, outLineDetail } from "@/api/outline";
import { testExecutionDetail, testExecutionSave } from "@/api/execution";
import SampleTable from "@/views/outline/components/table/sampleTable";
import Record from "../components/record";
import Warn from "@/views/carry/components/warn/Warn";
import Upload from "@/components/Upload";
import AnnealPrint from "@/views/print/record/annealPrint";
import RadiationPrint from "@/views/print/record/radiationPrint";
import TestPrint from "@/views/print/record/testPrint";
export default {
  data() {
    return {
      handleType: this.$route.query.type || "",
      sampleData: [],
      btnLoading: false,
      maxlength: 64,
      formData: {},
      isAddFuZhao: false,
      isAddTuiHuo: false,
      isAddCeShi: false,
      annealPrintvisible: false,
      radiationPrintvisible: false,
      testPrintvisible: false,
      annealTableData: [],
      radiationTableData: [],
      testTableData: [],
      formRules: {
        // 基本信息规则
        experimentName: [
          {
            required: true,
            message: "请输入试验名称",
            trigger: "blur"
          }
        ],
        radiationSource: [
          {
            required: true,
            message: "请选择实际辐射源",
            trigger: "change"
          }
        ],
        realityDoseRate: [
          {
            required: true,
            message: "请输入实际累积剂量点/注量点",
            trigger: "blur"
          }
        ],
        realityCondition1: [
          {
            required: true,
            message: "请输入实际辐照环境温度",
            trigger: "blur"
          }
        ],
        realityCondition1h: [
          {
            required: true,
            message: "请输入实际辐照环境湿度",
            trigger: "blur"
          }
        ],
        realityCondition2: [
          {
            required: true,
            message: "请输入实际测试环境温度",
            trigger: "blur"
          }
        ],
        realityCondition2h: [
          {
            required: true,
            message: "请输入实际测试环境湿度",
            trigger: "blur"
          }
        ],
        testProject: [
          {
            required: true,
            message: "请输入测试项目",
            trigger: "blur"
          }
        ],
        testDataPosition: [
          {
            required: true,
            message: "请输入测试数据保存位置",
            trigger: "blur"
          }
        ],
        electrostaticPrevention: [
          {
            required: true,
            message: "请输入防静电措施",
            trigger: "blur"
          }
        ],
        // 辐照过程记录规则
        radiationRecord1: [
          {
            required: true,
            message: "请输入样品对应偏置条件",
            trigger: "blur"
          }
        ],
        annealRecord2: [
          {
            required: true,
            message: "请输入实际累积剂量点/注量点",
            trigger: "blur"
          }
        ],
        radiationRecord5: [
          {
            required: true,
            message: "请选择降源后是否断电",
            trigger: "change"
          }
        ],
        radiationRecord6: [
          {
            required: true,
            message: "请输入记录人",
            trigger: "blur"
          }
        ],
        // 退火过程记录规则
        annealRecord1: [
          {
            required: true,
            message: "请输入样品对应偏置条件",
            trigger: "blur"
          }
        ],
        annealRecord5: [
          {
            required: true,
            message: "请选择退火结束后是否断电",
            trigger: "change"
          }
        ],
        annealRecord6: [
          {
            required: true,
            message: "请输入记录人",
            trigger: "blur"
          }
        ],
        // 测试过程记录规则
        testRecord1: [
          {
            required: true,
            message: "请输入实际累积剂量点/注量点",
            trigger: "blur"
          }
        ],
        testRecord2: [
          {
            required: true,
            message: "请输入实际累积测试时长",
            trigger: "blur"
          }
        ],
        testRecord3: [
          {
            required: true,
            message: "请选择测试起止时间",
            trigger: "change"
          }
        ],
        testRecord4: [
          {
            required: true,
            message: "请输入测试结果",
            trigger: "blur"
          }
        ],
        testRecord5: [
          {
            required: true,
            message: "请输入记录人",
            trigger: "blur"
          }
        ]
      },
      outlineData: {},
      radiationSource: []
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.testExecutionSave();
        } else {
          this.$message.error("请填写必要字段");
          return false;
        }
      });
    },
    // 保存试验实施
    testExecutionSave() {
      // 辐照辐照起止时间
      let radiationRecord3 = null;
      // 退火辐照起止时间
      let annealRecord3 = null;
      // 测试起止时间
      let testRecord3 = null;
      if (
        this.formData.radiationRecord3 &&
        this.formData.radiationRecord3.length
      ) {
        radiationRecord3 = this.formData.radiationRecord3.join(",");
      }
      if (this.formData.annealRecord3 && this.formData.annealRecord3.length) {
        annealRecord3 = this.formData.annealRecord3.join(",");
      }
      if (this.formData.testRecord3 && this.formData.testRecord3.length) {
        testRecord3 = this.formData.testRecord3.join(",");
      }
      let params = {
        ...this.formData,
        radiationRecord3,
        annealRecord3,
        testRecord3,
        testOutlineId: this.$route.query.outlineId
      };
      if (this.isAddFuZhao) {
        params.radiationProcessInfo = JSON.stringify(this.radiationTableData);
      }
      if (this.isAddTuiHuo) {
        params.annealingProcessInfo = JSON.stringify(this.annealTableData);
      }
      if (this.isAddCeShi) {
        params.testingProcessInfo = JSON.stringify(this.testTableData);
      }
      // 如果为编辑操作
      if (this.handleType === "edit") {
        params.executionId = this.$route.query.executionId;
      }
      testExecutionSave(params)
        .then(() => {
          this.btnLoading = true;
          this.$message({
            message: "操作成功",
            type: "success",
            onClose: () => {
              this.$router.replace({ name: "carry" });
            }
          });
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 获取大纲详情
    outLineDetail(id) {
      outLineDetail(id)
        .then(res => {
          this.outlineData = res.data;
          if (res.data.testSampleInfo) {
            this.sampleData = JSON.parse(res.data.testSampleInfo);
          }
          // 如果是实施的话，需要把大纲数据带过来
          if (!this.handleType) {
            if (res.data.radiationSource) {
              this.radiationSource = res.data.radiationSource.split(",");
            }
            /**
             * 实施:辐射源->大纲:辐射源
             * 实施:radiationSource
             * 大纲:radiationSource
             */
            this.$set(
              this.formData,
              "radiationSource",
              res.data.radiationSource
            );

            /**
             * 实施:实际剂量率/注量率->大纲:辐照剂量率要求（需注明剂量率允许范围）
             * 实施:realityDoseRate
             * 大纲:radiationRequire2
             */
            this.$set(
              this.formData,
              "realityDoseRate",
              res.data.radiationRequire2
            );

            /**
             * 实施:实际辐照环境条件->大纲:辐照环境条件要求（需注明要求的温、湿度范围）
             * 实施:realityCondition1
             * 大纲:radiationRequire4
             */
            this.$set(
              this.formData,
              "realityCondition1",
              res.data.radiationRequire4
            );

            /**
             * 实施:测试环境条件要求->大纲:测试环境条件要求（需注明要求的温、湿度范围）
             * 实施:realityCondition2
             * 大纲:testCondition1
             */
            this.$set(
              this.formData,
              "realityCondition2",
              res.data.testCondition1
            );

            /**
             * 实施:测试项目->大纲:测试项目
             * 实施:testProject
             * 大纲:testProject
             */

            this.$set(this.formData, "testProject", res.data.testProject);

            /**
             * 实施:防静电措施->大纲:防静电措施
             * 实施:electrostaticPrevention
             * 大纲:testCondition2
             */
            this.$set(
              this.formData,
              "electrostaticPrevention",
              res.data.testCondition2
            );

            /**
             * 辐照过程
             * 实施:样品对应偏置条件->大纲:偏置和负载条件要求
             * 实施:radiationRecord1
             * 大纲:radiationRequire3
             */

            this.$set(
              this.formData,
              "radiationRecord1",
              res.data.radiationRequire3
            );

            /**
             * 辐照过程
             * 实施:实际累积剂量点/注量点->大纲:预定的辐照剂量点设置
             * 实施:radiationRecord2
             * 大纲:radiationSetting
             */
            this.$set(
              this.formData,
              "radiationRecord2",
              res.data.radiationSetting
            );
            /**
             * 退火过程
             * 实施:样品对应偏置条件->实施:辐照过程的样品对应偏置条件
             * 实施:annealRecord1
             * 实施:radiationRecord1
             */
            this.$set(
              this.formData,
              "annealRecord1",
              this.formData.radiationRecord1
            );
          }

          this.formData.outlineName = res.data.outlineName;
          this.formData.applyUnit = res.data.applyUnit;
        })
        .catch(err => {
          console.log(err);
          this.$message.error("获取失败");
        });
    },
    // 获取试验实施详情
    testExecutionDetail() {
      testExecutionDetail(this.$route.query.executionId)
        .then(res => {
          if (res.data.annealRecord3) {
            res.data.annealRecord3 = res.data.annealRecord3.split(",");
          }
          if (res.data.radiationRecord3) {
            res.data.radiationRecord3 = res.data.radiationRecord3.split(",");
          }
          if (res.data.testRecord3) {
            res.data.testRecord3 = res.data.testRecord3.split(",");
          }
          if (res.data.radiationSource) {
            this.radiationSource = res.data.radiationSource.split(",");
          }

          if (res.data.radiationProcessInfo) {
            this.radiationTableData = JSON.parse(res.data.radiationProcessInfo);
            this.isAddFuZhao = true;
          }
          if (res.data.annealingProcessInfo) {
            this.annealTableData = JSON.parse(res.data.annealingProcessInfo);
            this.isAddTuiHuo = true;
          }
          if (res.data.testingProcessInfo) {
            this.testTableData = JSON.parse(res.data.testingProcessInfo);
            this.isAddCeShi = true;
          }
          this.formData = res.data;
        })
        .catch(err => {
          console.log(err);
          this.$message.error("获取失败");
        });
    },
    // 保存大纲的样品信息
    saveSampleInfo() {
      if (this.sampleData.length) {
        let testSampleInfo = JSON.stringify(this.sampleData);
        let params = {
          ...this.outlineData,
          testSampleInfo
        };
        outLineSave(params).then(() => {
          this.$message({
            message: "保存成功",
            type: "success"
          });
        });
      }
    },
    //添加记录
    isAddRecordHandle(type) {
      this[type] = !this[type];
    },
    // 打印记录
    printRecord(type) {
      if (type === "辐照") {
        this.radiationPrintvisible = true;
      } else if (type === "退火") {
        this.annealPrintvisible = true;
      } else if (type === "测试") {
        this.testPrintvisible = true;
      }
    },
    /**
     * 选择辐射源
     */
    radiationSourceChange() {
      this.formData.radiationSource = this.radiationSource.join(",");
    },
    toBack() {
      this.$router.go(-1);
    }
  },
  created() {
    if (this.$route.query.outlineId) {
      this.outLineDetail(this.$route.query.outlineId);
    }
    if (this.$route.query.executionId) {
      this.testExecutionDetail();
    }
  },
  components: {
    SampleTable,
    Warn,
    Record,
    Upload,
    AnnealPrint,
    RadiationPrint,
    TestPrint
  }
};
</script>

<style lang="scss" scoped>
#root {
  position: relative;
  .box-card {
    margin-bottom: 10px;
    .sample-info {
      font-size: 14px;
    }
  }
  .remark {
    margin: 0 10px;
    font-size: 13px;
  }
  .warn-info {
    color: #e6a23c;
    display: inline-block;
    .warn-img {
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 9px;
      color: #fff;
      font-size: 16px;
      background-color: #e6a23c;
      line-height: 18px;
      text-align: center;
      margin: 0 10px;
    }
    span {
      font-size: 12px;
    }
  }
}
</style>
