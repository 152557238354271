<template>
  <el-dialog
    center
    width="800px"
    ref="form"
    title="日志"
    :visible.sync="visible"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-table
      v-loading="tableLoading"
      :data="tableData"
      border
      stripe
      style="width: 100%;margin:20px 0"
      :header-cell-style="{ background: '#f8f8f9' }"
      @selection-change="handleSelectionChange"
      ref="lhsTable"
    >
      <el-table-column prop="auditStatus" label="审核状态" min-width="80">
        <template slot-scope="scope">
          {{ scope.row.auditStatus | statusHandle }}
        </template>
      </el-table-column>
      <el-table-column prop="auditUserName" label="审核人" min-width="80" />
      <el-table-column prop="auditOption" label="审核意见" min-width="200" />
    </el-table>
    <Page
      :total="totalNumber"
      @handleCurrentChangeSub="handleCurrentChangeFun"
    />
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { outlineHistory } from "@/api/outline";
import { status } from "@/utils/dic";

export default {
  /**
   * 业务id
   */
  props: {
    businessId: {
      type: String,
      default: "",
      required: true
    }
  },
  data() {
    return {
      visible: false,
      tableData: [],
      multipleSelection: [],
      tableLoading: true,
      totalNumber: 0
    };
  },
  methods: {
    open() {
      this.visible = true;
      this.$nextTick(() => {
        let params = {
          businessId: this.businessId
        };
        this.outlineHistory(params);
      });
    },
    // 添加设备
    submit() {
      this.visible = false;
      this.$emit("submit", this.multipleSelection);
      this.multipleSelection = [];
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCurrentChangeFun(page) {
      this.current = page;
      let params = {
        current: page,
        businessId: this.businessId
      };
      this.outlineHistory(params);
    },
    toCreateOutline() {
      this.$router.push({
        name: "outlineDetail"
      });
    },
    outlineHistory(params) {
      outlineHistory(params)
        .then(res => {
          this.tableLoading = false;
          this.tableData = res.data.records;
          this.totalNumber = res.data.total;
        })
        .catch(err => {
          this.tableLoading = false;
          console.log(err);
        });
    }
  },
  filters: {
    statusHandle(val) {
      val = parseInt(val);
      let result = "";
      if (val === status.CAO_GAO) {
        result = "草稿";
      } else if (val === status.BO_HUI) {
        result = "已驳回";
      } else if (val === status.JIAO_DUI) {
        result = "校对中";
      } else if (val === status.BIAO_SHEN) {
        result = "标审中";
      } else if (val === status.PI_ZHUN) {
        result = "批准中";
      } else if (val === status.YI_PI_ZHUN) {
        result = "已批准";
      }
      return result;
    }
  }
};
</script>

<style lang="scss" scoped></style>
