<template>
  <el-dialog
    center
    width="800px"
    ref="form"
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleCancel"
  >
    <el-form ref="form" :model="formData">
      <template v-if="recordType === '辐照'">
        <el-form-item label="是否正确">
          <el-radio-group v-model="formData.isCorrect">
            <el-radio label="是">是</el-radio>
            <el-radio label="否">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="辐照起止时间">
          <el-date-picker
            value-format="yyyy-MM-dd hh:mm:ss"
            v-model="formData.startToEnd"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :unlink-panels="true"
          />
        </el-form-item>
        <el-form-item label="剂量点/注量点">
          <el-input v-model="formData.dosePoint" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="断电确认" prop="isBlackOut">
          <el-radio-group v-model="formData.isBlackOut">
            <el-radio label="是">是</el-radio>
            <el-radio label="否">否</el-radio>
            <el-radio label="不涉及">不涉及</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="记录人">
          <el-input v-model="formData.recordPerson" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="异常情况记录">
          <el-input
            type="textarea"
            v-model="formData.abnormal"
            placeholder="请输入"
          />
        </el-form-item>
      </template>
      <template v-else-if="recordType === '退火'">
        <el-form-item label="是否正确">
          <el-radio-group v-model="formData.isCorrect">
            <el-radio label="是">是</el-radio>
            <el-radio label="否">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="退火起止时间">
          <el-date-picker
            value-format="yyyy-MM-dd hh:mm:ss"
            v-model="formData.startToEnd"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :unlink-panels="true"
          />
        </el-form-item>
        <el-form-item label="断电确认">
          <el-radio-group v-model="formData.isBlackOut">
            <el-radio label="是">是</el-radio>
            <el-radio label="否">否</el-radio>
            <el-radio label="不涉及">不涉及</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="记录人">
          <el-input v-model="formData.recordPerson" placeholder="请输入" />
        </el-form-item>
      </template>
      <template v-else-if="recordType === '测试'">
        <el-form-item label="剂量点/注量点">
          <el-input v-model="formData.dosePoint" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="累积退火时长">
          <Hhmmss v-model="formData.annelTime" />
        </el-form-item>
        <el-form-item label="测试起止时间">
          <el-date-picker
            value-format="yyyy-MM-dd hh:mm:ss"
            v-model="formData.startToEnd"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :unlink-panels="true"
          />
        </el-form-item>
        <el-form-item label="记录人">
          <el-input v-model="formData.recordPerson" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="测试结果">
          <el-input
            type="textarea"
            v-model="formData.testResult"
            placeholder="请输入"
          />
        </el-form-item>
      </template>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { isUndefined } from "@/utils";
import Hhmmss from "@/components/Hhmmss";

export default {
  name: "RecordDialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    recordType: {
      type: String,
      default: "辐照",
      validator: value => {
        // 这个值必须匹配下列字符串中的一个
        return ["辐照", "退火", "测试"].indexOf(value) !== -1;
      }
    }
  },
  data() {
    return {
      formData: {},
      accessTypes: ["辐照", "退火", "测试"]
    };
  },
  computed: {
    title() {
      if (isUndefined(this.formData.index)) {
        return "添加过程记录";
      }
      return "编辑过程记录";
    }
  },
  methods: {
    // 提交
    submit() {
      let formData = { ...this.formData };
      if (formData) {
        formData.startToEnd = formData.startToEnd.join(",");
      }
      this.$emit("submit", formData);
      this.handleCancel();
    },
    // 取消
    handleCancel() {
      this.$emit("update:visible", false);
    }
  },
  directives: {
    // 输入框正数限制
    positiveInt: {
      bind: function(el) {
        el.handler = function() {
          let input = el.querySelector("input");
          input.value = Number(input.value.replace(/\D+/, ""));
        };
        el.addEventListener("input", el.handler);
      },
      unbind: function(el) {
        el.removeEventListener("input", el.handler);
      }
    }
  },
  components: {
    Hhmmss
  }
};
</script>
