import request from "@/utils/request";
/**
 * 列表
 * @param {} params
 */
export function outlineList(params) {
  return request({
    url: "/testoutline/page",
    method: "GET",
    params
  });
}
/**
 * 详情
 * @param {} params
 */
export function outLineDetail(params) {
  return request({
    url: "/testoutline/detail/" + params,
    method: "GET"
  });
}
/**
 * 保存、编辑
 * @param {} params
 */
export function outLineSave(data) {
  return request({
    url: "/testoutline/save",
    method: "POST",
    data
  });
}
/**
 * 删除
 * @param {} params
 */
export function deleteOutlines(data) {
  return request({
    url: "/testoutline/ids/" + data,
    method: "DELETE"
  });
}

/**
 * 上传文件
 * @param {} params
 */
export function uploadFile() {
  return request({
    url: "/system/upload",
    method: "POST"
  });
}

/**
 * 下载文件
 * @param {} params
 */
export function downLoadFile(params) {
  return request({
    url: "/system/download",
    method: "GET",
    params
  });
}

/**
 * 大纲审核
 * @param {} params
 */
export function outlineAudit(data) {
  return request({
    url: "/testoutline/audit",
    method: "POST",
    data
  });
}

/**
 * 获取审批历史列表
 * @param {} params
 */
export function outlineHistory(params) {
  return request({
    url: "/testoutline/history",
    method: "GET",
    params
  });
}
