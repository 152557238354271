import request from "@/utils/request";
/**
 * 列表
 * @param {} params
 */
export function deviceList(params) {
  return request({
    url: "/equipment/manage/page",
    method: "GET",
    params
  });
}
/**
 * 保存、编辑
 * @param {} params
 */
export function deviceSave(data) {
  return request({
    url: "/equipment/manage/save",
    method: "POST",
    data
  });
}
/**
 * 删除
 * @param {} params
 */
export function devicesDelete(data) {
  return request({
    url: "/equipment/manage/ids/" + data,
    method: "DELETE"
  });
}
