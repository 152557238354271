<template>
  <el-table
    :data="tableData"
    border
    style="width: 100%;margin:20px 0"
    :header-cell-style="{ background: '#f8f8f9' }"
  >
    <el-table-column prop="Name" label="样品名称"> </el-table-column>
    <el-table-column prop="Num" label="样品编号"> </el-table-column>
    <el-table-column prop="Amount" label="样品数量">
      <template slot-scope="{ row }">
        <el-input
          v-positive-int
          type="number"
          v-model="row.Amount"
          @blur="inputBlur"
          :disabled="disabled"
        ></el-input>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    inputBlur() {
      this.$emit("inputBlur");
    }
  },
  directives: {
    // 输入框正数限制
    positiveInt: {
      bind: function(el) {
        el.handler = function() {
          let input = el.querySelector("input");
          input.value = Number(input.value.replace(/\D+/, ""));
        };
        el.addEventListener("input", el.handler);
      },
      unbind: function(el) {
        el.removeEventListener("input", el.handler);
      }
    }
  }
};
</script>
