<template>
  <el-dialog
    center
    ref="form"
    width="720px"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleCancel"
  >
    <div id="print" ref="print">
      <h3 class="title">退火过程记录表</h3>
      <p class="number">编号：<span class="under-line"></span></p>
      <table style="table-layout: fixed;">
        <colgroup>
          <col style="width: 72px" />
          <col style="width: 72px" />
          <col style="width: 72px" />
          <col style="width: 72px" />
          <col style="width: 72px" />
          <col style="width: 72px" />
          <col style="width: 72px" />
          <col style="width: 72px" />
          <col style="width: 72px" />
          <col style="width: 72px" />
        </colgroup>
        <thead>
          <tr>
            <th>退火设备</th>
            <td colspan="3">{{ carryData.annealingEquipment }}</td>
            <th>测试方法</th>
            <td colspan="5">{{ outlineData.testMethod }}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>退火温度</th>
            <td colspan="2">{{ carryData.annealingTemperature }}</td>
            <th>退火时长</th>
            <td colspan="2">{{ carryData.annealingTime }}</td>
            <th>环境温湿度</th>
            <td colspan="3">
              温度：{{ carryData.ambientTemperature }} ℃ 湿度：{{
                carryData.ambientHumidity
              }}
              %RH
            </td>
          </tr>
          <tr>
            <th>防静电措施</th>
            <td colspan="9">{{ carryData.electrostaticPrevention }}</td>
          </tr>
          <tr>
            <th colspan="4">退火前检查</th>
            <th colspan="6">退火过程记录</th>
          </tr>
          <tr>
            <th colspan="3">样品信息及其试验条件</th>
            <th>是否正确</th>
            <th colspan="2">退火开始时间</th>
            <th colspan="2">退火结束时间</th>
            <th>断电确认</th>
            <th>记录人</th>
          </tr>
          <tr v-for="(record, index) in records" :key="index">
            <td v-if="index === 0" colspan="3" rowspan="2">
              {{ carryData.radiationRecord1 }}
            </td>
            <td>{{ record.isCorrect }}</td>
            <td colspan="2">{{ record.startToEnd | chinesizeTime(0) }}</td>
            <td colspan="2">{{ record.startToEnd | chinesizeTime(1) }}</td>
            <td>{{ record.isBlackOut }}</td>
            <td>{{ record.recordPerson }}</td>
          </tr>
          <tr>
            <th>备注</th>
            <td colspan="9"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="print">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { chinesizeTime } from "@/utils";
export default {
  props: {
    carryData: {
      type: Object,
      default: () => {}
    },
    outlineData: {
      type: Object,
      default: () => {}
    },
    records: {
      type: Array,
      default: () => []
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    print() {
      setTimeout(() => {
        this.$print(this.$refs.print);
        this.handleCancel();
      }, 300);
    },
    handleCancel() {
      this.$emit("update:visible", false);
    }
  },
  filters: {
    chinesizeTime(times, index) {
      let isSecond = true;
      return chinesizeTime(times[index], isSecond);
    }
  }
};
</script>

<style scoped>
.text-indent {
  text-indent: 2em;
}
table,
table tr th,
table tr td {
  border: 1px solid #000;
  padding: 5px;
}
table {
  border-collapse: collapse;
  text-align: center;
  font-size: 14px;
  table-layout: fixed;
  word-break: break-all;
  word-wrap: break-word;
}
td {
  text-align: left;
}
.title {
  text-align: center;
}
.under-line {
  display: inline-block;
  min-width: 150px;
  border-bottom: 1px solid #000;
  text-align: left;
}
.number {
  text-align: right;
}
@media print {
  nav,
  aside {
    display: none;
  }

  body,
  article {
    font-size: 13pt;
    font-family: "宋体", "SimSun", "serif", "sans-serif", "cursive", "fantasy",
      "monospace";
    line-height: 1.3;
    width: 100%;
    margin: 0;
    padding: 0;
    color: #000;
    background: #fff;
  }

  @page {
    margin: 1cm;
    size: A4;
  }
}
</style>
