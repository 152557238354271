<template>
  <div>
    <el-row>
      <el-button type="primary" icon="el-icon-plus" @click="showAddView"
        >添加角色</el-button
      >
    </el-row>

    <el-row>
      <el-table
        :data="tableData"
        border
        stripe
        style="width: 100%;margin:20px 0"
        :header-cell-style="{ background: '#f8f8f9' }"
      >
        <el-table-column prop="name" label="角色名称" min-width="180" />
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="showEditView(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="deleteRow(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- <Page :total="totalNumber" @handleCurrentChangeSub="handleCurrentChangeFun" /> -->
    </el-row>

    <el-dialog
      :title="dialogTitle"
      style="padding: 0px;"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      top="60px"
      :visible.sync="dialogVisible"
      :append-to-body="true"
    >
      <el-form
        ref="dialogForm"
        :model="dialogForm"
        label-position="right"
        label-width="100px"
        style="margin: 0px;padding: 0px;"
        :rules="rules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="角色名称:" prop="name">
              <el-input
                v-model="dialogForm.name"
                :disabled="isEdit"
                placeholder="请输入角色名称"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="24">
            <el-form-item label="角色说明:" prop="email">
              <el-input v-model="dialogForm.desc" style placeholder="请输入角色说明"></el-input>
            </el-form-item>
          </el-col>
        </el-row>-->
        <el-row>
          <el-col :span="24">
            <el-form-item label="角色资源:">
              <el-tree
                ref="tree"
                :props="props"
                :data="treeData"
                :default-expanded-keys="['2', '3']"
                :default-checked-keys="checkedKeys"
                node-key="id"
                show-checkbox
                highlight-current
                @check-change="handleCheckChange"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="saveDialogForm()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getRoleList, getRoleDetail, saveRole, deleteRole } from "@/api/user";
import { setTimeout } from "timers";
export default {
  name: "Roles",
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      isEdit: false,
      dialogTitle: "添加角色",
      dialogVisible: false,
      searchForm: {
        name: ""
      },
      dialogForm: {
        name: "",
        desc: ""
      },
      rules: {
        name: [{ required: true, message: "请输入角色名", trigger: "blur" }]
      },
      totalNumber: 0,
      tableData: [],
      props: {
        label: "name",
        children: "children"
      },
      treeData: [
        {
          id: "1",
          path: "/outline",
          name: "实施大纲"
        },
        {
          id: "2",
          path: "/carry",
          name: "试验实施"
        },
        {
          id: "4",
          path: "/device",
          name: "试验设备"
        },
        {
          id: "6",
          path: "/dict",
          name: "数据字典"
        },
        {
          id: "7",
          name: "系统管理",
          expand: true,
          children: [
            {
              id: "7_1",
              path: "/users",
              name: "用户管理"
            },
            {
              id: "7_2",
              path: "/roles",
              name: "角色管理"
            }
          ]
        }
      ],
      checkedKeys: []
    };
  },
  computed: {},
  watch: {
    dialogVisible(v) {
      var _this = this;
      if (!v) {
        setTimeout(() => {
          _this.$refs.tree.setCheckedKeys([]);
        }, 100);
      }
    }
  },
  mounted() {
    this.loadData(1);
  },
  methods: {
    handleCurrentChangeFun(page) {
      this.loadData(page);
    },
    loadData(page) {
      this.loading = true;
      getRoleList({ page })
        .then(res => {
          this.loading = false;
          this.tableData = res.data;
          this.totalNumber = res.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleCheckChange(data, checked, indeterminate) {
      if (data.id) {
        // this.checkedKeys.push(data.id);
      }
    },
    showAddView() {
      this.dialogTitle = "添加角色";
      this.dialogVisible = true;
      this.isEdit = false;
      this.$refs["dialogForm"] && this.$refs["dialogForm"].resetFields();
      this.dialogForm = {
        id: "",
        name: ""
      };
      var _this = this;
      setTimeout(() => {
        _this.$refs.tree.setCheckedKeys([]);
      }, 100);
    },
    showEditView(row) {
      this.dialogTitle = "编辑角色";
      this.isEdit = true;
      this.$refs["dialogForm"] && this.$refs["dialogForm"].resetFields();
      this.dialogForm = {
        id: row.id,
        name: row.name
      };
      // this.checkedKeys = [];
      var _this = this;
      getRoleDetail(row.id).then(res => {
        this.dialogVisible = true;
        // this.$refs.tree.setCheckedNodes([]);
        setTimeout(() => {
          _this.$refs.tree.setCheckedKeys(res.data.menuIds);
        }, 100);

        // this.checkedKeys = res.data.menuIds;
      });
    },
    saveDialogForm() {
      // getCheckedNodes
      var ids = this.$refs.tree.getCheckedKeys(true);
      if (ids.length < 1) {
        this.$message({
          message: "请至少选择一个资源节点!",
          type: "error"
        });
        return false;
      }
      // debugger;
      // var ids = [];
      // nodes.forEach(v => ids.push(v.id));
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          this.loading = true;
          const data = {
            id: this.dialogForm.id,
            name: this.dialogForm.name,
            roleMenus: ids.join()
          };
          saveRole(data)
            .then(res => {
              this.loading = false;
              this.dialogVisible = false;

              this.loadData(1);
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    deleteRow(row) {
      this.$confirm(`此操作将永久删除[ ${row.name} ], 是否继续?`, "提示", {
        closeOnClickModal: false,
        closeOnPressEscape: false,
        type: "warning"
      })
        .then(() => {
          this.doDelete(row.id);
        })
        .catch(() => {});
    },
    doDelete(id) {
      this.tableLoading = true;
      var _this = this;
      deleteRole(id)
        .then(res => {
          this.loadData(1);
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss"></style>
