import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    defaultFlow: ""
  },
  mutations: {
    setDefaultFlow(state, flowCode) {
      state.defaultFlow = flowCode;
    }
  },
  actions: {}
});
