<template>
  <el-dialog
    center
    width="500px"
    ref="form"
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form ref="form" :model="formData" :rules="formRules">
      <el-form-item label="设备名称" prop="pyhsicalEquName">
        <el-input
          placeholder="请输入"
          v-model="formData.pyhsicalEquName"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备技术指标、精度等要求" prop="pyhsicalEquTarget">
        <el-input
          placeholder="请输入"
          type="textarea"
          v-model="formData.pyhsicalEquTarget"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnLoading"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      btnLoading: false,
      formData: {},
      title: "添加设备",
      formRules: {
        pyhsicalEquName: [
          {
            required: true,
            message: "请输入设备名称",
            trigger: "blur"
          }
        ],
        pyhsicalEquTarget: [
          {
            required: true,
            message: "请输入设备要求",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    open(row) {
      this.visible = true;
      this.btnLoading = false;
      if (row) {
        this.formData = row;
      } else {
        this.formData = {};
      }
    },
    // 提交
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.visible = false;
          this.$emit("submit", this.formData);
        } else {
          this.$message.error("请填写必要字段");
          return false;
        }
      });
    }
  }
};
</script>
