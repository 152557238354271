<template>
  <div class="login-container">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      autocomplete="on"
      label-position="left"
    >
      <div class="title-container">
        <h3 class="title">
          <img src="@/assets/logo.png" />辐射效应数据库智能管理系统
        </h3>
      </div>

      <div style="background-color:#fff;padding:24px;border-radius:6px;">
        <h3 style="margin:0 0 20px 0">系统登录</h3>
        <el-form-item prop="userName">
          <el-input
            ref="userName"
            v-model="loginForm.userName"
            prefix-icon="el-icon-user"
            placeholder="请输入账号"
            name="userName"
            type="text"
            tabindex="1"
            autocomplete="on"
            size="large"
          />
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            prefix-icon="el-icon-lock"
            type="password"
            placeholder="请输入密码"
            name="password"
            tabindex="2"
            autocomplete="on"
            size="large"
            @keyup.enter.native="handleLogin"
          />
        </el-form-item>

        <el-button
          :loading="loading"
          type="primary"
          style="width:40%;"
          size="large"
          @click.native.prevent="handleLogin"
          >登录</el-button
        >
        <!-- <el-button
          style="width:40%;"
          size="large"
          @click.native.prevent="handleRegister"
          >注册</el-button
        > -->
      </div>
    </el-form>

    <el-dialog
      title="用户注册"
      style="padding: 0px;"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="450px"
    >
      <el-form
        ref="dialogForm"
        :model="dialogForm"
        label-position="right"
        label-width="100px"
        style="margin: 0px;padding: 0px;"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="登录账号:" prop="name">
              <el-input
                v-model="dialogForm.name"
                :disabled="isEdit"
                placeholder="请输入登录账号"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="密码:" prop="password">
              <el-input
                v-model="dialogForm.desc"
                type="password"
                style
                placeholder="请输入密码"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="确认密码:" prop="password">
              <el-input
                v-model="dialogForm.desc"
                type="password"
                style
                placeholder="请输入确认密码"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="saveDialogForm()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { login } from "@/api/user";
import { setToken, setUserInfo, setRoleInfo } from "@/utils";
import { Base64 } from "js-base64";

export default {
  name: "Login",
  props: {
    name: {
      type: String
    }
  },
  data() {
    return {
      dialogVisible: false,
      loginForm: {
        userName: "",
        password: ""
      },
      isEdit: false,
      dialogForm: {},
      loginRules: {
        userName: [{ required: true, trigger: "blur", message: "请输入账号" }],
        password: [{ required: true, trigger: "blur", message: "请输入密码" }]
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {}
    };
  },
  computed: {},
  watch: {},
  mounted() {
    if (this.loginForm.userName === "") {
      this.$refs.userName.focus();
    } else if (this.loginForm.password === "") {
      this.$refs.password.focus();
    }
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          this.loginForm.password = Base64.encode(this.loginForm.password);
          login(this.loginForm)
            .then(res => {
              setToken(res.data.token);
              setUserInfo(res.data.userInfo.user);
              setRoleInfo(res.data.userInfo.roleInfo);
              this.loading = false;
              //获取用户列表权限
              let roleMenuList = res.data.userInfo.roleInfo.roleMenuList;
              if (roleMenuList.length) {
                this.$router.push({ name: roleMenuList[0].routeName });
              } else {
                this.$router.push("/");
              }
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleRegister() {
      this.dialogVisible = true;
    },
    saveDialogForm() {}
  }
};
</script>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  // background-color: $bg;
  background-image: url("../../assets/login_bg.png");
  overflow: hidden;

  .login-form {
    position: relative;
    width: 450px;
    max-width: 100%;
    padding: 100px 35px 0;
    margin: 0 auto;
    overflow: hidden;
    text-align: center;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 22px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
    .title img {
      margin-right: 5px;
      vertical-align: middle;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
</style>
