<template>
  <div id="root">
    <div class="search-area">
      <h1>试验实施列表</h1>
      <el-input
        v-model="keywords"
        style="width:250px"
        placeholder="请输入关键字查询"
      />
      <el-button
        icon="el-icon-search"
        style="margin-left:10px;"
        type="primary"
        @click="searchList('search')"
        >查询</el-button
      >
      <el-button @click="searchList('reset')">重置</el-button>
    </div>
    <div class="body-area">
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        border
        stripe
        style="width: 100%;margin:20px 0"
        :header-cell-style="{ background: '#f8f8f9' }"
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column type="selection" align="left" width="40" /> -->
        <el-table-column
          prop="experimentName"
          label="试验名称"
          min-width="200"
        />
        <el-table-column
          prop="outlineName"
          label="试验大纲名称"
          min-width="200"
        />
        <el-table-column prop="applyUnit" label="委托单位" min-width="200" />
        <el-table-column fixed="right" label="操作" width="195" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="handleClick('report', row)"
              >生成报告</el-button
            >
            <el-button type="text" @click="handleClick('find', row)"
              >查看</el-button
            >
            <el-button type="text" @click="handleClick('edit', row)"
              >编辑</el-button
            >
            <el-button type="text" @click="handleClick('delete', row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <Page
        :total="totalNumber"
        @handleCurrentChangeSub="handleCurrentChangeFun"
      />
    </div>
  </div>
</template>

<script>
import { testExecutionList, deleteExecutions } from "@/api/execution";

export default {
  data() {
    return {
      keywords: "",
      totalNumber: 0,
      multipleSelection: [],
      tableLoading: true,
      tableData: []
    };
  },
  methods: {
    handleCurrentChangeFun(page) {
      this.current = page;
      let params = { outlineName: this.keywords, current: page };
      this.testExecutionList(params);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 搜索查询列表
    searchList(kind) {
      let params = {};
      if (kind === "search") {
        params.outlineName = this.keywords;
      } else {
        this.keywords = null;
      }
      this.testExecutionList(params);
    },
    // 获取试验实施列表
    testExecutionList(params) {
      testExecutionList(params)
        .then(res => {
          this.tableLoading = false;
          this.tableData = res.data.records;
          this.totalNumber = res.data.total;
        })
        .catch(err => {
          this.tableLoading = false;
          console.log(err);
        });
    },
    handleClick(kind, row) {
      if (kind === "find" || kind === "edit") {
        this.$router.push({
          name: "carryCreate",
          query: {
            executionId: row.id,
            type: kind,
            outlineId: row.testOutlineId
          }
        });
      } else if (kind === "report") {
        this.$router.push({
          name: "report",
          params: { outlineId: row.testOutlineId, carryId: row.id }
        });
        // this.$refs.ReportDialog.open(row);
      } else if (kind === "delete") {
        this.$confirm("您确定删除该数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: "warning"
        }).then(() => {
          this.deleteExecutions(row.id);
        });
      }
    },
    // 删除试验实施数据
    deleteExecutions(id) {
      deleteExecutions(id)
        .then(() => {
          this.checkPage();
          this.$message.success("删除成功");
          this.keywords = "";
          let params = { current: this.current };
          this.testExecutionList(params);
        })
        .catch(() => {
          this.$message.error("删除失败");
        });
    },
    // 判断跳转
    checkPage() {
      if (
        this.current > 1 &&
        this.tableData.length === this.multipleSelection.length
      ) {
        this.current = this.current - 1;
      }
    }
  },
  created() {
    this.testExecutionList();
  }
};
</script>

<style lang="scss" scoped></style>
