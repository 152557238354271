<template>
  <div>
    <div class="search-area">
      <h1>试验大纲</h1>
      <el-input
        v-model="searchForm.nickName"
        placeholder="姓名"
        style="width:250px"
      />
      <el-input
        style="width:250px;margin-left:10px"
        v-model="searchForm.mobile"
        placeholder="手机号"
      />
      <el-button
        style="margin-left:10px;"
        icon="el-icon-search"
        type="primary"
        @click="doSearch"
        >查询</el-button
      >
      <el-button @click="resetSearch">重置</el-button>
      <div style="margin-top:10px">
        <el-button icon="el-icon-plus" type="primary" @click="showAddView"
          >添加用户</el-button
        >
        <el-button
          type="danger"
          :disabled="multipleSelection.length == 0"
          @click="multipleDelete"
          >批量删除</el-button
        >
      </div>
    </div>
    <el-row>
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        border
        stripe
        style="width: 100%;margin:20px 0"
        :header-cell-style="{ background: '#f8f8f9' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="left" width="40" />
        <el-table-column prop="userName" label="账号" min-width="180" />
        <el-table-column prop="nickName" label="姓名" min-width="180" />
        <el-table-column prop="mobile" label="手机号" min-width="180" />
        <el-table-column prop="roleName" label="角色" min-width="180" />

        <el-table-column fixed="right" label="操作" width="175" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="showEditView(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="deleteRow(scope.row)"
              >删除</el-button
            >
            <el-button type="text" @click="resetPwd(scope.row)"
              >重置密码</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <Page
        :total="totalNumber"
        @handleCurrentChangeSub="handleCurrentChangeFun"
      />
    </el-row>

    <el-dialog
      :title="dialogTitle"
      style="padding: 0px;"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      top="60px"
      :visible.sync="dialogVisible"
    >
      <el-form
        ref="dialogForm"
        :model="dialogForm"
        label-position="right"
        label-width="100px"
        style="margin: 0px;padding: 0px;"
        :rules="rules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="账号:" prop="userName">
              <el-input
                v-model="dialogForm.userName"
                :disabled="isEdit"
                style
                placeholder="请输入账号"
                :maxlength="18"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="姓名:" prop="nickName">
              <el-input
                v-model="dialogForm.nickName"
                style
                placeholder="请输入姓名"
                :maxlength="10"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="手机号:" prop="mobile">
              <el-input
                v-model="dialogForm.mobile"
                style
                placeholder="请输入手机号"
                :maxlength="11"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="角色:" prop="roleId">
              <el-select
                v-model="dialogForm.roleId"
                style="width:100%"
                placeholder="请选择角色"
              >
                <el-option
                  v-for="item in roleList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="初始化密码:">
              <el-input disabled value="lihuasuo@258" style />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveDialogForm()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserPage,
  saveUser,
  deleteUser,
  getRoleList,
  resetPassword
} from "@/api/user";
export default {
  name: "Users",
  components: {},
  props: {},
  data() {
    var validateMobile = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
        return false;
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号"));
        return false;
      }
      callback();
    };
    return {
      tableLoading: false,
      isEdit: false,
      dialogTitle: "添加用户",
      dialogVisible: false,
      searchForm: {
        nickName: "",
        mobile: ""
      },
      rules: {
        userName: [{ required: true, message: "请输入账号", trigger: "blur" }],
        nickName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          {
            required: true,
            validator: validateMobile,
            trigger: "blur"
          }
        ],
        roleId: [{ required: true, message: "请选择角色", trigger: "change" }]
      },
      roleList: [],
      dialogForm: {
        id: "",
        userName: "",
        nickName: "",
        mobile: "",
        roleId: ""
      },
      multipleSelection: [],
      tableData: [],
      totalNumber: 0,
      page: 1
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.loadData();
  },
  methods: {
    handleCurrentChangeFun(page) {
      this.page = page;
      this.loadData();
    },
    loadData(params) {
      this.loading = true;
      var data = { current: this.page };
      if (params) {
        data = { current: this.page, ...params };
      }
      getUserPage(data)
        .then(res => {
          this.loading = false;
          this.tableData = res.data.records;
          this.totalNumber = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadRoleList() {
      getRoleList().then(res => {
        this.roleList = res.data;
      });
    },
    doSearch() {
      this.loadData(this.searchForm);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    showAddView() {
      this.dialogTitle = "添加用户";
      this.dialogVisible = true;
      this.isEdit = false;
      this.loadRoleList();
      this.$refs["dialogForm"] && this.$refs["dialogForm"].resetFields();

      this.dialogForm = {
        id: "",
        userName: "",
        nickName: "",
        mobile: "",
        roleId: ""
      };
    },
    showEditView(row) {
      this.dialogTitle = "编辑用户";
      this.dialogVisible = true;
      this.isEdit = true;
      this.loadRoleList();
      this.$refs["dialogForm"] && this.$refs["dialogForm"].resetFields();
      this.dialogForm = {
        id: row.id,
        userName: row.userName,
        nickName: row.nickName,
        mobile: row.mobile,
        roleId: row.roleId
      };
    },
    saveDialogForm() {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          this.loading = true;
          saveUser(this.dialogForm)
            .then(res => {
              this.loading = false;
              this.dialogVisible = false;
              this.searchForm = {};
              this.loadData();
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    deleteRow(row) {
      this.$confirm(`此操作将永久删除[ ${row.nickName} ], 是否继续?`, "提示", {
        closeOnClickModal: false,
        closeOnPressEscape: false,
        type: "warning"
      })
        .then(() => {
          this.doDelete(row.id);
        })
        .catch(() => {});
    },
    multipleDelete() {
      this.$confirm(
        `此操作将删除[ ${this.multipleSelection.length} ]条数据, 是否继续?`,
        "提示",
        {
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: "warning"
        }
      )
        .then(() => {
          var ids = [];
          this.multipleSelection.forEach(v => ids.push(v.id));
          this.doDelete(ids.join());
        })
        .catch(() => {});
    },
    doDelete(ids) {
      this.tableLoading = true;
      deleteUser(ids)
        .then(res => {
          this.searchForm = {};
          this.tableLoading = false;
          this.loadData();
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    // 重置密码row
    resetPwd(row) {
      if (row.id) {
        this.$confirm(
          `此操作将重置“${row.nickName}”用户密码, 是否继续?`,
          "提示",
          {
            closeOnClickModal: false,
            closeOnPressEscape: false,
            type: "warning"
          }
        )
          .then(() => {
            resetPassword({ id: row.id })
              .then(() => {
                this.$message.success("重置成功");
              })
              .catch(err => {
                this.$message.error(err.message);
              });
          })
          .catch(() => {});
      }
    },
    resetSearch() {
      this.searchForm = {};
      this.page = 1;
      this.loadData();
    }
  }
};
</script>

<style lang="scss" scoped>
.el-dialog {
  width: calc(100vw - 220px) !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  .el-dialog__body {
    height: calc(100vh - 175px) !important;
    overflow: auto;
  }
  .upload-demo-none {
    .el-upload {
      display: none;
    }
  }
}
</style>
