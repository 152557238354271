import Vue from "vue";
import Router from "vue-router";
import Main from "./views/main";
import Login from "./views/login";
import Users from "./views/users";
import Roles from "./views/roles";
import Carry from "./views/carry/list";
import Outline from "./views/outline/list";
import CarryCreate from "./views/carry/detail";
import Device from "./views/device";
import Report from "./views/print/report/report";
import ErrorPage from "./views/error/error";

Vue.use(Router);
import { getRoleInfo, getToken } from "@/utils";
import { Message } from "element-ui";
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      meta: { access: "login" },
      component: Login
    },
    {
      path: "/",
      name: "home",
      meta: { access: "home" },
      component: Main,
      children: [
        {
          path: "/users",
          name: "users",
          meta: { access: "users", auth: true },
          component: Users
        },
        {
          path: "/roles",
          name: "roles",
          meta: { access: "roles", auth: true },
          component: Roles
        },
        {
          path: "/dict",
          name: "dict",
          meta: { access: "dict", auth: true },
          component: () => import("@/views/dict/dict")
        },
        {
          path: "/outline",
          name: "outline",
          meta: { access: "outline", auth: true },
          component: Outline
        },
        {
          path: "/outline/detail",
          name: "outlineDetail",
          meta: { access: "outlineDetail" },
          component: () => import("@/views/outline/detail")
        },
        {
          path: "/carry",
          name: "carry",
          meta: { access: "carry", auth: true },
          component: Carry
        },
        {
          path: "/carry/create",
          name: "carryCreate",
          meta: { access: "carryCreate" },
          component: CarryCreate
        },
        {
          path: "/device",
          name: "device",
          meta: { access: "device", auth: true },
          component: Device
        },
        {
          path: "/print/report/:outlineId/:carryId",
          name: "report",
          meta: { access: "report" },
          component: Report
        },
        {
          path: "/print/outline/:outlineId",
          name: "printOutline",
          meta: { access: "printOutline" },
          component: () => import("@/views/print/outline")
        },
        {
          path: "/",
          name: "home",
          meta: { access: "outline", auth: true },
          redirect: "/outline"
        },
        {
          path: "*",
          component: ErrorPage
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.name === "login") {
    next();
  } else {
    var token = getToken();
    if (!token) {
      next({
        name: "login"
      });
      return false;
    }
    const roleMenuList = getRoleInfo().roleMenuList;
    var access = [];
    roleMenuList.forEach(v => {
      access.push(v.routeName);
    });
    var rout = to.meta.access;
    var isAuth = to.meta.auth;
    if (access.length < 1) {
      Message({
        message: "您暂无权限访问其请联系管理员!",
        type: "error",
        duration: 2 * 1000
      });
      // next({
      //   name: "login"
      // });
      return false;
    }
    if (to.name === "home") {
      next(access[0]);
      return false;
    }
    if (rout && isAuth && !access.includes(rout)) {
      Message({
        message: "您没有权限访问该页面!",
        type: "error",
        duration: 2 * 1000
      });
      next({
        name: access[0]
      });
    } else {
      next();
    }
  }
});

export default router;
