import Vue from "vue";
import "./plugins/fontawesome";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import Page from "./components/Page/index.js";
import "./styles/index.scss";
import "./styles/custom.scss";
import Print from "@/plugins/print";
Vue.config.productionTip = false;
Vue.use(Print);
Vue.use(Page);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
