<template>
  <el-dialog
    center
    width="1000px"
    ref="form"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleCancel"
  >
    <div id="print" ref="print">
      <h3 class="number">
        编号：
        <span class="under-line">{{ printData.basic.carryNum }}</span>
      </h3>
      <h2 class="experiment-name">{{ printData.carry.experimentName }}</h2>
      <div class="report-content">
        <h3>
          报告编制：
          <span class="under-line">
            {{ printData.basic.preparePeople }}
          </span>
        </h3>
        <h3>
          报告校对：
          <span class="under-line">
            {{ printData.basic.proofreadPeople }}
          </span>
        </h3>
        <h3>
          报告审核：
          <span class="under-line">
            {{ printData.basic.auditPeople }}
          </span>
        </h3>
        <h3>
          报告批准：
          <span class="under-line">
            {{ printData.basic.approvePeople }}
          </span>
        </h3>
      </div>
      <h4 class="center">
        中国科学院新疆理化技术研究所电子元器件辐射效应实验室（盖章）
      </h4>
      <h4 class="center">{{ printData.basic.stampTime | chinesizeTime }}</h4>
      <div style="page-break-after: always;"></div>
      <table style="width:100%" cellspacing="0">
        <tr>
          <th>
            委托方单位名称
          </th>
          <td>{{ printData.carry.applyUnit }}</td>
        </tr>
        <tr>
          <th>委托方单位地址</th>
          <td>{{ printData.outline.applyAddress }}</td>
        </tr>
        <tr>
          <th>实验室名称</th>
          <td>{{ printData.basic.labName }}</td>
        </tr>
        <tr>
          <th>实验室地址</th>
          <td>{{ printData.basic.labAddress }}</td>
        </tr>
        <tr>
          <th>试验地点</th>
          <td>{{ printData.basic.testAddress }}</td>
        </tr>
      </table>

      <h4 class="title">一、试验目的</h4>
      <p class="text-indent">
        {{ printData.outline.experimentPurpose }}
      </p>
      <h4 class="title">二、 试验样品描述和标识</h4>
      <table style="width:100%" cellspacing="0" border="1">
        <tr>
          <th valign="middle" align="center" style="width:30px" rowspan="2">
            被试件
          </th>
          <th>样品编号</th>
          <th>名称</th>
          <th>数量</th>
        </tr>
        <tr
          v-for="(sample, index) in printData.outline.testSampleInfo"
          :key="index"
        >
          <td>{{ sample.Num }}</td>
          <td>{{ sample.Name }}</td>
          <td>{{ sample.Amount }}</td>
        </tr>
      </table>

      <h4 class="title">三、 使用方法依据的标准或规范</h4>
      <p class="text-indent">
        {{ printData.outline.quotedDocument }}
      </p>
      <h4 class="title">四、 辐照时间及试验人员</h4>
      <div class="text-indent">
        <div>试验时间：{{ printData.carry.radiationRecord3 }}</div>
        <div>剂量管理员：{{ printData.basic.dosagePeople }}</div>
        <div>钴源操作员：{{ printData.basic.cobaltPeople }}</div>
        <div>元器件测试人员：{{ printData.basic.capHandlePeople }}</div>
        <div>材料样品测试人员：{{ printData.basic.materialSamplesPeople }}</div>
        <div>项目负责人：{{ printData.basic.projectLeaderPeople }}</div>
        <div>试验员：{{ printData.basic.testPeople }}</div>
      </div>
      <div class="text-indent">
        <h4 class="title">五、 辐照参数及条件</h4>
        <div>辐射源及辐照剂量率：{{ printData.outline.radiationRequire2 }}</div>
        <div>辐射源：{{ printData.carry.radiationSource }}</div>
        <div>辐照剂量率：{{ printData.carry.radiationRecord2 }}</div>
        <div>辐照总剂量：{{ printData.basic.allIrradiationNum }}</div>
        <div>
          温度：{{ printData.outline.wendu }}，湿度：{{
            printData.outline.shidu
          }}；
        </div>
        <div>辐照偏置条件：{{ printData.outline.radiationRequire3 }}</div>
      </div>
      <h4 class="title">六、 试验过程</h4>
      <p v-for="(data, index) in printData.testProcess" :key="index">
        <span v-if="data.content">{{ index + 1 }}.</span>
        <span>{{ data.content }}</span>
      </p>
      <h4 class="title">七、 结论</h4>
      <p class="text-indent">{{ printData.basic.conclusion }}</p>
      <h4 class="title">八、 声明</h4>
      <p class="text-indent">{{ printData.basic.statement }}</p>
      <div style="page-break-after: always;"></div>
      <div class="final-content">
        <h3>
          报告编制：
          <span class="under-line"> {{ printData.basic.preparePeople }} </span
          >日期：
          <span class="under-line">
            {{ printData.basic.prepareDate | chinesizeTime }}
          </span>
        </h3>
        <h3>
          报告校对：
          <span class="under-line"> {{ printData.basic.proofreadPeople }} </span
          >日期：
          <span class="under-line">
            {{ printData.basic.proofreadDate | chinesizeTime }}
          </span>
        </h3>
        <h3>
          报告审核：
          <span class="under-line"> {{ printData.basic.auditPeople }} </span
          >日期：
          <span class="under-line">
            {{ printData.basic.auditDate | chinesizeTime }}
          </span>
        </h3>
        <h3>
          报告批准：
          <span class="under-line"> {{ printData.basic.approvePeople }} </span
          >日期：
          <span class="under-line">
            {{ printData.basic.approveDate | chinesizeTime }}
          </span>
        </h3>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="print">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { chinesizeTime } from "@/utils";
export default {
  props: {
    printData: {
      type: Object,
      default: () => {}
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    print() {
      setTimeout(() => {
        this.$print(this.$refs.print);
        this.handleCancel();
      }, 300);
    },
    handleCancel() {
      this.$emit("update:visible", false);
    }
  },
  filters: {
    chinesizeTime(time) {
      return chinesizeTime(time);
    }
  }
};
</script>

<style scoped>
.center {
  text-align: center;
}
.number {
  font-weight: normal;
  text-align: right;
}
.title {
  text-align: left;
}
.text-indent {
  text-indent: 2em;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
}
.under-line {
  display: inline-block;
  min-width: 200px;
  border-bottom: 1px solid #000;
  text-align: left;
}
table,
table tr th,
table tr td {
  border: 1px solid #000;
  padding: 5px;
}
table {
  border-collapse: collapse;
  text-align: left;
  font-size: 14px;
}

@media print {
  h1 {
    color: #000;
    background: none;
  }
  .number {
    margin-bottom: 5cm !important;
  }
  .experiment-name {
    margin-bottom: 6cm !important;
  }
  .report-content {
    margin-bottom: 6cm !important;
  }
  .final-content {
    margin-top: 8cm !important;
  }
  nav,
  aside {
    display: none;
  }

  body,
  article {
    font-size: 13pt;
    font-family: "宋体", "SimSun", "serif", "sans-serif", "cursive", "fantasy",
      "monospace";
    line-height: 1.3;
    width: 100%;
    margin: 0;
    padding: 0;
    color: #000;
    background: #fff;
  }

  @page {
    margin: 2cm;
    size: A4;
  }
}
</style>
