<template>
  <div id="root">
    <el-form :model="formData" label-position="left">
      <h2>{{ formData.carry.experimentName }}</h2>
      <el-divider></el-divider>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>基本信息</span>
        </div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="委托方单位名称：" label-width="124px">{{
              formData.carry.applyUnit
            }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="委托方单位地址：" label-width="124px">{{
              formData.outline.applyAddress
            }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="试验目的：" label-width="82px">{{
              formData.outline.experimentPurpose
            }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="试验编号：" label-width="82px">
              <el-input
                v-model="formData.basic.carryNum"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="盖章日期：" label-width="82px">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="formData.basic.stampTime"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="试验地点：" label-width="82px">
              <el-input
                v-model="formData.basic.testAddress"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="实验室地址：" label-width="96px">
              <el-input
                v-model="formData.basic.labAddress"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="实验室名称：" label-width="96px">
              <el-input
                v-model="formData.basic.labName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>试验样品描述和标识</span>
        </div>
        <el-row :gutter="20">
          <el-row>
            <el-table
              :data="formData.outline.testSampleInfo"
              border
              style="width: 100%;margin:20px 0"
              :header-cell-style="{ background: '#f8f8f9' }"
            >
              <el-table-column prop="Num" label="样品编号"> </el-table-column>
              <el-table-column prop="Name" label="样品名称"> </el-table-column>
              <el-table-column prop="Amount" label="样品数量"></el-table-column>
            </el-table>
          </el-row>
        </el-row>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>使用方法依据的标准或规范</span>
        </div>
        <el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="内容：" label-width="54px">
                <el-input
                  autosize
                  placeholder="请输入"
                  type="textarea"
                  v-model="formData.outline.quotedDocument"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>辐照时间及试验人员</span>
        </div>
        <el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="试验时间：" label-width="82px">{{
                formData.carry.radiationRecord3
              }}</el-form-item>
            </el-col>
            <el-col :span="6" :offset="2">
              <el-form-item label="剂量管理员：" label-width="96px">
                <!-- {{formData.carry.radiationRecord6}} -->
                <el-input
                  v-model="formData.basic.dosagePeople"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" :offset="2">
              <el-form-item label="钴源操作员：" label-width="96px">
                <el-input
                  v-model="formData.basic.cobaltPeople"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="5">
              <el-form-item label="元器件测试人：" label-width="110px">
                <el-input
                  v-model="formData.basic.capHandlePeople"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5" :offset="1">
              <el-form-item label="材料样品测试人：" label-width="124px">
                <el-input
                  v-model="formData.basic.materialSamplesPeople"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5" :offset="1">
              <el-form-item label="项目负责人：" label-width="96px">
                <el-input
                  v-model="formData.basic.projectLeaderPeople"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5" :offset="1">
              <el-form-item label="试验员：" label-width="68px">
                <el-input
                  v-model="formData.basic.testPeople"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>辐照参数及条件</span>
        </div>
        <el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="辐射源及辐照剂量率：" label-width="152px">{{
                formData.outline.radiationRequire2
              }}</el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="辐射源：" label-width="68px">
                {{ formData.carry.radiationSource }}
              </el-form-item>
            </el-col>
            <el-col :span="6" :offset="1">
              <el-form-item label="辐照剂量率：" label-width="96px">
                {{ formData.carry.radiationRecord2 }}</el-form-item
              >
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="3">
              <el-form-item label="温度：" label-width="54px">
                {{ formData.outline.wendu }}
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="湿度：" label-width="54px">
                {{ formData.outline.shidu }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="辐照偏置条件：" label-width="110px">
                {{ formData.outline.radiationRequire3 }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="辐照总剂量：" label-width="96px">
                <el-input
                  v-model="formData.basic.allIrradiationNum"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>试验过程</span>
          <el-button
            type="primary"
            style="float:right;font-size:14px;"
            @click="addTestProcess"
            >添加过程</el-button
          >
        </div>
        <el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                :label="'过程' + (index + 1)"
                label-width="68px"
                v-for="(data, index) in formData.testProcess"
                :key="index"
              >
                <el-input
                  autosize
                  v-model="formData.testProcess[index].content"
                  placeholder="请输入试验过程"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>结论</span>
        </div>
        <el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="内容：" label-width="54px">
                <el-input
                  autosize
                  v-model="formData.basic.conclusion"
                  type="textarea"
                  placeholder="请输入试验结论"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>声明</span>
        </div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="内容：" label-width="54px">
              <el-input
                autosize
                placeholder="请输入声明"
                v-model="formData.basic.statement"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="报告编制人：" label-width="96px">
                <el-input
                  v-model="formData.basic.preparePeople"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="报告编制日期：" label-width="110px">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="formData.basic.prepareDate"
                  type="date"
                  placeholder="选择日期"
                  :picker-options="startDateDisabled"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="报告校对人：" label-width="96px">
                <el-input
                  v-model="formData.basic.proofreadPeople"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="报告校对日期：" label-width="110px">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="formData.basic.proofreadDate"
                  type="date"
                  placeholder="选择日期"
                  :picker-options="startDateDisabled"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="报告审核人：" label-width="96px">
                <el-input
                  v-model="formData.basic.auditPeople"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="报告审核日期：" label-width="110px">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="formData.basic.auditDate"
                  type="date"
                  placeholder="选择日期"
                  :picker-options="startDateDisabled"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="报告批准人：" label-width="96px">
                <el-input
                  v-model="formData.basic.approvePeople"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="报告批准日期：" label-width="110px">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="formData.basic.approveDate"
                  type="date"
                  placeholder="选择日期"
                  :picker-options="startDateDisabled"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
      </el-card>
    </el-form>
    <div class="footer">
      <div class="footer-btn">
        <el-button size="medium" type="primary" @click="print">打印</el-button>
        <el-button size="medium" @click="toBack">取消</el-button>
      </div>
    </div>
    <Print :printData="formData" :visible.sync="visible" />
  </div>
</template>

<script>
import { outLineDetail } from "@/api/outline";
import { testExecutionDetail } from "@/api/execution";
import Print from "./print";
import { chinesizeTime } from "@/utils";

export default {
  data() {
    return {
      formData: {
        outline: {},
        carry: {},
        basic: {
          statement:
            "实验室对报告中的所有信息负责，顾客提供的信息除外。本报告仅在签字完毕且加盖实验室骑缝公章后方才有效。本报告仅用于XXX项目的XX器件的抗总剂量能力摸底/考核/评估/验证试验，未经实验室书面同意不准部分或全部复制报告的内容。"
        },
        testProcess: [{ content: "" }]
      },
      visible: false,
      formLabelWidth: "110px",
      startDateDisabled: {
        disabledDate: time => {
          return time.getTime() + 24 * 3600 * 1000 > Date.now();
        }
      }
    };
  },
  methods: {
    getDatas() {
      if (this.$route.params.outlineId) {
        // 获取大纲数据
        this.outLineDetail(this.$route.params.outlineId);
      }
      if (this.$route.params.carryId) {
        // 获取试验实施数据
        this.testExecutionDetail(this.$route.params.carryId);
      }
    },
    // 获取大纲详情
    outLineDetail(id) {
      outLineDetail(id)
        .then(res => {
          if (res.data.radiationRequire4) {
            let radiationRequire4 = res.data.radiationRequire4.split(",");
            res.data.wendu = radiationRequire4[0];
            res.data.shidu = radiationRequire4[1];
          }
          if (res.data.testSampleInfo) {
            res.data.testSampleInfo = JSON.parse(res.data.testSampleInfo);
          }
          this.formData.outline = res.data;
        })
        .catch(err => {
          console.log(err);
          this.$message.error("获取失败");
        });
    },
    // 获取试验实施详情
    testExecutionDetail(id) {
      testExecutionDetail(id)
        .then(res => {
          if (res.data.annealRecord3) {
            res.data.annealRecord3 = res.data.annealRecord3.split(",");
          }
          if (res.data.testRecord3) {
            res.data.testRecord3 = res.data.testRecord3.split(",");
          }
          if (res.data.radiationRecord3) {
            let radiationRecord3 = res.data.radiationRecord3.split(",");
            let radiationRecord3Str =
              chinesizeTime(radiationRecord3[0]) +
              " 至 " +
              chinesizeTime(radiationRecord3[1]);
            res.data.radiationRecord3 = radiationRecord3Str;
          }
          this.formData.carry = res.data;
        })
        .catch(err => {
          console.log(err);
          this.$message.error("获取失败");
        });
    },
    // 添加过程
    addTestProcess() {
      this.formData.testProcess.push({ content: "" });
    },
    print() {
      this.visible = true;
    },
    toBack() {
      this.$router.go(-1);
    }
  },
  created() {
    this.getDatas();
  },
  components: {
    Print
  }
};
</script>
