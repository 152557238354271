<template>
  <div id="root">
    <div class="search-area">
      <h1>试验设备列表</h1>
      <el-input
        v-model="keywords"
        style="width:250px"
        placeholder="请输入关键字查询"
      />
      <el-button
        icon="el-icon-search"
        style="margin-left:10px"
        type="primary"
        @click="searchList('search')"
        >查询</el-button
      >
      <el-button @click="searchList('reset')">重置</el-button>
      <div style="margin-top:10px">
        <el-button type="primary" @click="toCreateDevice">+新增设备</el-button>
        <el-button
          type="danger"
          @click="batchDelete"
          :disabled="multipleSelection.length == 0"
          >批量删除</el-button
        >
      </div>
    </div>
    <div class="body-area">
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        border
        stripe
        style="width: 100%;margin:20px 0"
        :header-cell-style="{ background: '#f8f8f9' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="left" width="40" />
        <el-table-column
          prop="pyhsicalEquName"
          label="设备名称"
          min-width="150"
        />
        <el-table-column
          prop="pyhsicalEquTarget"
          label="设备技术指标、精度等要求"
          min-width="150"
        />
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleClick('delete', scope.row)"
              >删除</el-button
            >
            <el-button type="text" @click="handleClick('edit', scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <Page
        :total="totalNumber"
        @handleCurrentChangeSub="handleCurrentChangeFun"
      />
      <DeviceDialog @submit="createDevice" ref="DeviceDialog" />
    </div>
  </div>
</template>

<script>
import { deviceList, deviceSave, devicesDelete } from "@/api/device";
import DeviceDialog from "@/views/device/components/dialog/deviceDialog";

export default {
  data() {
    return {
      keywords: "",
      totalNumber: 0,
      tableLoading: true,
      tableData: [],
      multipleSelection: []
    };
  },
  methods: {
    //   新增设备
    toCreateDevice() {
      this.$refs.DeviceDialog.title = "添加设备";
      this.$refs.DeviceDialog.open();
    },
    batchDelete() {
      if (this.multipleSelection.length) {
        this.$confirm("您确定删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: "warning"
        }).then(() => {
          let ids = [];
          this.multipleSelection.forEach(element => {
            ids.push(element.id);
          });
          let idsStr = ids.join(",");
          this.devicesDelete(idsStr);
        });
      } else {
        this.$message.error("请勾选需要删除的行");
      }
    },
    // 搜索查询列表
    searchList(kind) {
      let params = {};
      if (kind === "search") {
        params.pyhsicalEquName = this.keywords;
      } else {
        this.keywords = null;
      }
      this.deviceList(params);
    },
    handleCurrentChangeFun(page) {
      this.current = page;
      let params = {
        current: page
      };
      this.deviceList(params);
    },
    deviceList(params) {
      deviceList(params)
        .then(res => {
          this.tableLoading = false;
          this.tableData = res.data.records;
          this.totalNumber = res.data.total;
        })
        .catch(err => {
          this.tableLoading = false;
          console.log(err);
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleClick(kind, row) {
      if (kind === "edit") {
        this.$refs.DeviceDialog.title = "编辑设备";
        this.$refs.DeviceDialog.open({ ...row });
      } else {
        this.$confirm("您确定删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: "warning"
        }).then(() => {
          this.devicesDelete(row.id);
        });
      }
    },
    // 删除大纲数据
    devicesDelete(id) {
      devicesDelete(id)
        .then(() => {
          this.checkPage();
          this.$message.success("删除成功");
          let params = {
            pyhsicalEquName: this.keywords,
            current: this.current
          };
          this.deviceList(params);
        })
        .catch(() => {
          this.$message.error("删除失败");
        });
    },
    // 新增设备
    createDevice(data) {
      deviceSave(data)
        .then(() => {
          this.$refs.DeviceDialog.btnLoading = true;
          this.$message.success("操作成功");
          let params = {
            pyhsicalEquName: this.keywords,
            current: this.current
          };
          this.deviceList(params);
        })
        .catch(() => {
          this.$refs.DeviceDialog.btnLoading = false;
          this.$message.error("操作失败");
        });
    },
    // 判断跳转
    checkPage() {
      if (
        this.current > 1 &&
        this.tableData.length === this.multipleSelection.length
      ) {
        this.current = this.current - 1;
      }
    }
  },
  created() {
    this.deviceList();
  },
  components: {
    DeviceDialog
  }
};
</script>

<style lang="scss" scoped></style>
