import request from "@/utils/request";
/**
 * 列表
 * @param {} params
 */
export function testExecutionList(params) {
  return request({
    url: "/testexecution/page",
    method: "GET",
    params
  });
}
/**
 * 详情
 * @param {} params
 */
export function testExecutionDetail(params) {
  return request({
    url: "/testexecution/detail/" + params,
    method: "GET"
  });
}
/**
 * 保存、编辑
 * @param {} params
 */
export function testExecutionSave(data) {
  return request({
    url: "/testexecution/save",
    method: "POST",
    data
  });
}
/**
 * 删除
 * @param {} paramss
 */
export function deleteExecutions(data) {
  return request({
    url: "/testexecution/ids/" + data,
    method: "DELETE"
  });
}
