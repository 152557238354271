<template>
  <div class="warn-info" v-show="iShow">
    <div class="warn-img">!</div>
    <span
      >大纲录入{{ outlineLabel }}：{{
        outlineVal | arrayToString(compareType)
      }}</span
    >
  </div>
</template>

<script>
import { isSameArray } from "@/utils";

export default {
  props: {
    outlineVal: {
      type: [Array, String, Number]
    },
    carryVal: {
      type: [Array, String, Number]
    },
    outlineLabel: {
      type: String,
      default: ""
    },
    compareType: {
      type: String,
      default: "same",
      validator: value => {
        // 需要比较的类型
        return ["arr", "range", "same"].indexOf(value) !== -1;
      }
    }
  },
  data() {
    return {
      iShow: false
    };
  },
  watch: {
    carryVal: {
      handler() {
        if (this.compareType === "arr" && Array.isArray(this.outlineVal)) {
          let outlineVal = this.outlineVal.split(",");
          let carryVal = this.carryVal.split(",");
          this.iShow = !isSameArray(outlineVal, carryVal);
        } else if (this.compareType === "range") {
          if (this.outlineVal) {
            // 范围比较
            let range = this.outlineVal;
            let carryVal = parseFloat(this.carryVal || 0);
            range = range.split(",");
            // 最小值
            let min = parseFloat(range[0]);
            // 最大值
            let max = parseFloat(range[1]);
            if (carryVal < min || carryVal > max) {
              this.iShow = true;
            } else {
              this.iShow = false;
            }
          }
        } else {
          if (this.outlineVal && this.carryVal) {
            this.iShow = this.outlineVal !== this.carryVal;
          }
        }
      },
      immediate: true
    }
  },
  filters: {
    arrayToString(val, compareType) {
      if (compareType === "arr" && Array.isArray(val)) {
        return val.join(",");
      } else if (compareType === "range" && val) {
        return val.replace(",", "~") + "C°";
      } else {
        return val;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.warn-info {
  color: #e6a23c;
  display: inline-block;
  .warn-img {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    color: #fff;
    font-size: 16px;
    background-color: #e6a23c;
    line-height: 18px;
    text-align: center;
    margin: 0 10px;
  }
  span {
    font-size: 12px;
  }
}
</style>
