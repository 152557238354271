/**
 * 角色常量
 */

const role = {
  JIAO_DUI: "4676337f8d3b79034db1874930126d6c",
  BIAO_SHEN: "1aa041089312819614638221bdd2d16a",
  PI_ZHUN: "abb6d853c6007f488267b4aee0ff366b"
};

/**
 * 审核状态该常量
 */
const status = {
  CAO_GAO: 0,
  BO_HUI: 1,
  JIAO_DUI: 2,
  BIAO_SHEN: 3,
  PI_ZHUN: 4,
  YI_PI_ZHUN: 5
};

/**
 * 字典类型
 */
const dict = {
  APPLY_UNIT: { Nub: 100, label: "委托方单位" },
  APPLY_UNIT_ADDRESS: { Nub: 101, label: "委托方单位地址" },
  INCLUDE_FILE: { Nub: 102, label: "引用文件" }
};

export { role, status, dict };
