<template>
  <el-aside width="220px" style="background-color: #304156">
    <logo />
    <el-menu
      background-color="#304156"
      text-color="#bfcbd9"
      :default-openeds="['3']"
      @select="menuChange"
      :default-active="defaultIndex"
    >
      <el-menu-item index="outline" v-if="hasAuth('outline')">
        <template slot="title">
          <i class="el-icon-printer" />
          <span>实施大纲</span>
        </template>
      </el-menu-item>
      <el-menu-item index="carry" v-if="hasAuth('carry')">
        <template slot="title">
          <i class="el-icon-printer" />
          <span>试验实施</span>
        </template>
      </el-menu-item>
      <el-menu-item index="device" v-if="hasAuth('device')">
        <template slot="title">
          <i class="el-icon-printer" />
          <span>试验设备</span>
        </template>
      </el-menu-item>
      <el-menu-item index="dict" v-if="hasAuth('dict')">
        <template slot="title">
          <i class="el-icon-printer" />
          <span>数据字典</span>
        </template>
      </el-menu-item>
      <el-submenu v-if="hasAuth('system')" index="3">
        <template slot="title">
          <i class="el-icon-setting" />
          <span>系统管理</span>
        </template>
        <el-menu-item v-if="hasAuth('users')" index="users">
          <template slot="title">
            <i class="el-icon-message" />
            <span slot="title">用户管理</span>
          </template>
        </el-menu-item>
        <el-menu-item index="roles">
          <template slot="title">
            <i v-if="hasAuth('roles')" class="el-icon-message" />
            <span slot="title">角色管理</span>
          </template>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </el-aside>
</template>

<script>
import Logo from "./Logo.vue";
import { getRoleInfo } from "@/utils";

export default {
  name: "Sidebar",
  components: { Logo },
  data() {
    return {
      roleMenus: [],
      defaultIndex: this.$route.name || "outline"
    };
  },
  computed: {
    key() {
      return this.$route.path;
    }
  },
  mounted() {
    const tt = getRoleInfo();
    const roleMenuList = tt.roleMenuList;
    if (roleMenuList) {
      var _this = this;
      roleMenuList.forEach(function(v, k) {
        _this.roleMenus.push(v.routeName);
      });
    }
  },
  methods: {
    menuChange(index) {
      this.$router.push({ name: index });
    },
    hasAuth(routePath) {
      if (this.roleMenus.indexOf(routePath) != -1) {
        return true;
      }
      if (routePath === "system") {
        return this.hasAuth("users") || this.hasAuth("roles");
      }
      return false;
    },
    changeIndex(index) {
      console.log(index);
      this.defaultIndex = index;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-menu {
  border: none !important;
}
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
.menu-item {
  color: rgb(191, 203, 217);
  text-decoration: none;
}
.el-menu-item [class^="el-icon-"] {
  font-size: 19px;
}
</style>
