<template>
  <div id="root">
    <el-upload
      class="avatar-uploader"
      :action="uploadUrl"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
      :headers="headers"
      :on-remove="handleRemove"
      :disabled="isDisabled"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon" />
    </el-upload>
    <div v-if="imageUrl" class="btn-area">
      <el-button @click="handleDownload" type="text">下载</el-button>
      <el-button @click="handlePictureCardPreview" type="text">预览</el-button>
      <el-button @click="handleRemove" type="text" style="color:#F56C6C"
        >删除</el-button
      >
    </div>
    <el-dialog
      append-to-body
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <img width="100%" :src="imageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { getToken, download } from "@/utils";
import { downLoadFile } from "@/api/outline";
import "../../public/config.js";
export default {
  props: {
    kind: {
      type: String,
      default: ""
    },
    fileId: {
      type: String,
      default: ""
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "fileId",
    event: "update"
  },
  data() {
    return {
      imageUrl: "",
      dialogVisible: false,
      uploadUrl:
        process.env.NODE_ENV === "development"
          ? "/system/upload"
          : window.baseUrl + "/system/upload",
      isDisabled: false,
      headers: {
        Authorization: getToken()
      }
    };
  },
  watch: {
    fileId: {
      handler() {
        if (this.fileId) {
          let url =
            process.env.NODE_ENV === "development"
              ? "/system/download?fileId="
              : window.baseUrl + "/system/download?fileId=";
          this.imageUrl = url + this.fileId;
        } else {
          this.imageUrl = "";
        }
      },
      deep: true,
      immediate: true
    },
    imageUrl() {
      if (this.imageUrl) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    }
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = null;
      this.imageUrl = URL.createObjectURL(file.raw);
      this.$emit("update", res.data.id);
    },
    beforeAvatarUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isJpgOrPng) {
        this.$message.error("图片仅支持jpg或png");
      }
      if (!isLt20M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJpgOrPng && isLt20M;
    },
    handleRemove() {
      this.imageUrl = null;
      this.$emit("update", "");
    },
    handlePictureCardPreview() {
      this.dialogVisible = true;
    },
    handleDownload() {
      if (this.fileId) {
        downLoadFile({ fileId: this.fileId }).catch(err => {
          download(err, "test.jpg");
        });
      } else {
        this.$message.error("下载失败");
      }
    }
  }
};
</script>
<style scoped lang="scss">
#root {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 200px;
    height: 200px;
    line-height: 200px;
    text-align: center;
    background-color: #f5f5f5;
  }
  .avatar {
    width: 200px;
    height: 200px;
    display: block;
  }
  .btn-area {
    width: 200px;
    text-align: center;
  }
}
</style>
