import request from "@/utils/request";
/**
 * 登录
 * @param {} params
 */
export function login(data) {
  return request({
    url: "/login",
    method: "POST",
    data
  });
}
/**
 * 个人信息
 * @param {} params
 */
export function userInfo(params) {
  return request({
    url: "/auth/user",
    method: "GET",
    params
  });
}
export function getUserPage(params) {
  return request({
    url: "/system/user/page",
    method: "GET",
    params
  });
}

export function saveUser(params) {
  return request({
    url: "system/user",
    method: "POST",
    data: params
  });
}
export function deleteUser(params) {
  return request({
    url: "system/users/" + params,
    method: "DELETE",
    data: params
  });
}
/**
 * 角色列表
 * @param {} params
 */
export function getRoleList(params) {
  return request({
    url: "/system/roles/list",
    method: "GET",
    params
  });
}
export function getRoleDetail(params) {
  return request({
    url: `system/roles/${params}/detail`,
    method: "GET"
  });
}
export function saveRole(params) {
  return request({
    url: "system/role",
    method: "POST",
    data: params
  });
}
export function deleteRole(params) {
  return request({
    url: "system/roles/" + params,
    method: "DELETE"
  });
}
export function roleDetail(params) {
  return request({
    url: `/system/roles/${params}/detail`,
    method: "GET"
  });
}
// 重置密码
export function resetPassword(data) {
  return request({
    url: "system/user/reset",
    method: "POST",
    data
  });
}
