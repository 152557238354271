<template>
  <div id="root">
    <span>{{ recordType }}过程记录</span>
    <el-button style="float:right" type="primary" @click="createRecord"
      >添加过程记录</el-button
    >
    <el-table
      v-loading="tableLoading"
      :data="tableData"
      border
      stripe
      style="width: 100%;margin:20px 0"
      :header-cell-style="{ background: '#f8f8f9' }"
    >
      <template v-if="recordType === '辐照'">
        <el-table-column prop="isCorrect" label="是否正确" width="100" />
        <el-table-column
          prop="dosePoint"
          label="剂量点/注量点"
          min-width="100"
        />
        <el-table-column prop="startToEnd" label="辐照起止时间" min-width="100">
          <template slot-scope="{ row }">
            {{ row.startToEnd | timeToStr }}
          </template>
        </el-table-column>
        <el-table-column prop="abnormal" label="异常情况记录" min-width="100" />
        <el-table-column prop="isBlackOut" label="断电确认" width="100" />
        <el-table-column prop="recordPerson" label="记录人" width="100" />
      </template>

      <template v-else-if="recordType === '退火'">
        <el-table-column prop="isCorrect" label="是否正确" />
        <el-table-column prop="startToEnd" label="退火起止时间">
          <template slot-scope="{ row }">
            {{ row.startToEnd | timeToStr }}
          </template>
        </el-table-column>
        <el-table-column prop="isBlackOut" label="断电确认" />
        <el-table-column prop="recordPerson" label="记录人" />
      </template>

      <template v-else-if="recordType === '测试'">
        <el-table-column prop="dosePoint" label="剂量点/注量点" />
        <el-table-column prop="annelTime" label="累积退火时长">
          <template slot-scope="{ row }">
            {{ row.annelTime | timeStr }}
          </template>
        </el-table-column>
        <el-table-column prop="startToEnd" label="测试起止时间">
          <template slot-scope="{ row }">
            {{ row.startToEnd | timeToStr }}
          </template>
        </el-table-column>
        <el-table-column prop="recordPerson" label="记录人" />
        <el-table-column prop="testResult" label="测试结果" />
      </template>

      <el-table-column
        v-if="handleType !== 'find'"
        fixed="right"
        label="操作"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="handleClick('edit', scope.row, scope.$index)"
            >编辑</el-button
          >
          <el-button
            type="text"
            @click="handleClick('delete', scope.row, scope.$index)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <RecordDialog
      :recordType="recordType"
      :visible.sync="visible"
      @submit="submit"
      ref="recordDialog"
    />
  </div>
</template>

<script>
import RecordDialog from "../dialog/recordDialog";
import { isUndefined } from "@/utils";

export default {
  name: "Record",
  model: {
    prop: "dataSource",
    event: "update"
  },
  props: {
    handleType: {
      type: String,
      default: ""
    },
    recordType: {
      type: String,
      default: "辐照",
      validator: value => {
        // 这个值必须匹配下列字符串中的一个
        return ["辐照", "退火", "测试"].indexOf(value) !== -1;
      }
    },
    dataSource: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    dataSource: {
      handler() {
        this.tableData = this.dataSource;
      },
      immediate: true
    }
  },
  data() {
    return {
      tableLoading: false,
      tableData: [],
      visible: false,
      popVisible: false
    };
  },
  methods: {
    createRecord() {
      this.visible = true;
      this.$refs.recordDialog.formData = {};
    },
    handleClick(type, row, index) {
      if (type === "edit") {
        this.visible = true;
        // 进行深拷贝
        let data = JSON.stringify(row);
        data = JSON.parse(data);
        // 把时间转为数字形式
        if (data.startToEnd) {
          data.startToEnd = data.startToEnd.split(",");
        }
        this.$refs.recordDialog.formData = data;
        this.$refs.recordDialog.formData.index = index;
      } else if (type === "delete") {
        this.$confirm("您确定删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: "warning"
        }).then(() => {
          this.tableData.splice(index, 1);
          this.$emit("update", this.tableData);
        });
      }
    },
    // 确定添加
    submit(data) {
      if (isUndefined(data.index)) {
        this.tableData.push(data);
      } else {
        this.$set(this.tableData, data.index, data);
      }
      this.$emit("update", this.tableData);
    }
  },
  filters: {
    timeToStr(time) {
      if (time) {
        return time.replace(",", "至");
      }
      return "";
    },
    timeStr(time) {
      if (!time) return "";
      let arr = time.split(",");
      return arr[0] + "时" + arr[1] + "分" + arr[2] + "秒";
    }
  },
  components: {
    RecordDialog
  }
};
</script>

<style scoped lang="scss">
#root {
  position: relative;
  .box-card {
    margin-bottom: 10px;
    .add-btn {
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 28px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    }
  }
}
</style>
