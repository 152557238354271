<template>
  <div id="root">
    <el-input
      :disabled="disabled"
      type="number"
      v-model="timeVal"
      v-positive-int
      @input="getVal"
      :style="{ width: width + 'px' }"
    ></el-input>
    <span>时</span>
    <el-input
      :disabled="disabled"
      type="number"
      v-model="minuteVal"
      v-positive-int
      @input="getVal"
      :style="{ width: width + 'px' }"
    ></el-input>
    <span>分</span>
    <el-input
      :disabled="disabled"
      type="number"
      v-model="secondVal"
      v-positive-int
      @input="getVal"
      :style="{ width: width + 'px' }"
    ></el-input>
    <span>秒</span>
  </div>
</template>

<script>
export default {
  name: "Hhmmss",
  model: {
    prop: "value",
    event: "inputChange"
  },
  props: {
    width: {
      type: Number,
      default: 80
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ""
    }
  },
  data: function() {
    return {
      timeVal: "",
      minuteVal: "",
      secondVal: ""
    };
  },
  watch: {
    value(times) {
      if (times) {
        let arr = times.split(",");
        this.timeVal = arr[0];
        this.minuteVal = arr[1];
        this.secondVal = arr[2];
      }
    }
  },
  methods: {
    getVal() {
      const val = this.timeVal + "," + this.minuteVal + "," + this.secondVal;
      this.$emit("inputChange", val);
    }
  },
  directives: {
    // 输入框正数限制
    positiveInt: {
      bind: function(el) {
        el.handler = function() {
          let input = el.querySelector("input");
          input.value = Number(input.value.replace(/\D+/, ""));
        };
        el.addEventListener("input", el.handler);
      },
      unbind: function(el) {
        el.removeEventListener("input", el.handler);
      }
    }
  }
};
</script>

<style></style>
