<template>
  <el-dialog
    center
    width="810px"
    ref="form"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleCancel"
  >
    <div id="print" ref="print">
      <h3 class="title">测试过程记录表</h3>
      <p class="number">编号：<span class="under-line"></span></p>
      <table style="table-layout: fixed;">
        <colgroup>
          <col style="width: 90px" />
          <col style="width: 90px" />
          <col style="width: 90px" />
          <col style="width: 90px" />
          <col style="width: 90px" />
          <col style="width: 90px" />
          <col style="width: 90px" />
          <col style="width: 90px" />
          <col style="width: 90px" />
        </colgroup>
        <thead>
          <tr>
            <th>测试方法</th>
            <td colspan="8">{{ outlineData.testMethod }}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>测试依据</th>
            <td colspan="8">{{ outlineData.quotedDocument }}</td>
          </tr>
          <tr>
            <th>测试参数</th>
            <td colspan="8">{{ carryData.testProject }}</td>
          </tr>
          <tr>
            <th>数据保存位置</th>
            <td colspan="8">{{ carryData.testDataPosition }}</td>
          </tr>
          <tr>
            <th>测试环境条件</th>
            <td colspan="8">
              温度： {{ carryData.realityCondition2 }}℃ 湿度：{{
                carryData.realityCondition2h
              }}
              %RH
            </td>
          </tr>
          <tr>
            <th>防静电措施</th>
            <td colspan="8">{{ carryData.electrostaticPrevention }}</td>
          </tr>
          <tr>
            <th colspan="2">样品名称及编号</th>
            <th colspan="1">剂量点/注量点</th>
            <th colspan="1">累积退火时长</th>
            <th colspan="2">测试日期及时长</th>
            <th>记录人</th>
            <th colspan="2">测试结果</th>
          </tr>
          <tr v-for="(record, index) in records" :key="index">
            <td colspan="2">
              <span v-if="sampleData.length">
                &nbsp;样品名称：{{ sampleData[0].Name }}，样品编号：{{
                  sampleData[0].Num
                }}</span
              >
            </td>
            <td colspan="1">&nbsp;{{ record.dosePoint }}</td>
            <td colspan="1">&nbsp;{{ record.annelTime | timeStr }}</td>
            <td colspan="2">&nbsp;{{ record.startToEnd | arrToStr }}</td>
            <td>&nbsp;{{ record.recordPerson }}</td>
            <td colspan="2">&nbsp;{{ record.testResult }}</td>
          </tr>
          <tr>
            <th>备注</th>
            <td colspan="8">{{ carryData.testRecord6 }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="print">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { chinesizeTime } from "@/utils";
export default {
  props: {
    carryData: {
      type: Object,
      default: () => {}
    },
    outlineData: {
      type: Object,
      default: () => {}
    },
    records: {
      type: Array,
      default: () => []
    },
    visible: {
      type: Boolean,
      default: false
    },
    sampleData: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    print() {
      setTimeout(() => {
        this.$print(this.$refs.print);
        this.handleCancel();
      }, 300);
    },
    handleCancel() {
      this.$emit("update:visible", false);
    }
  },
  filters: {
    chinesizeTime(time) {
      return chinesizeTime(time);
    },
    arrToStr(time) {
      if (time) return time.replace(",", "至");
      return "";
    },
    timeStr(time) {
      if (!time) return "";
      let arr = time.split(",");
      return arr[0] + "时" + arr[1] + "分" + arr[2] + "秒";
    }
  }
};
</script>

<style scoped>
.text-indent {
  text-indent: 2em;
}
table,
table tr th,
table tr td {
  border: 1px solid #000;
  padding: 5px;
}

table {
  border-collapse: collapse;
  text-align: center;
  font-size: 14px;
  table-layout: fixed;
  word-break: break-all;
  word-wrap: break-word;
}
td {
  text-align: left;
}
.col-one {
  width: 150px;
}
.col-two {
  width: 400px;
}
.title {
  text-align: center;
}
.under-line {
  display: inline-block;
  min-width: 150px;
  border-bottom: 1px solid #000;
  text-align: left;
}
.number {
  text-align: right;
}
@media print {
  nav,
  aside {
    display: none;
  }

  body,
  article {
    font-size: 13pt;
    font-family: "宋体", "SimSun", "serif", "sans-serif", "cursive", "fantasy",
      "monospace";
    line-height: 1.3;
    width: 100%;
    margin: 0;
    padding: 0;
    color: #000;
    background: #fff;
  }

  @page {
    margin: 2cm;
    size: A4;
  }
}
</style>
