<template>
  <div id="root">
    <div class="search-area">
      <h1>大纲打印</h1>
      <el-input
        v-model="keywords"
        style="width:250px"
        placeholder="请输入关键字查询"
      />
      <el-button
        icon="el-icon-search"
        style="margin-left:10px;"
        type="primary"
        @click="searchList('search')"
        >查询</el-button
      >
      <el-button @click="searchList('reset')">重置</el-button>
      <div style="margin-top:10px">
        <el-button icon="el-icon-plus" type="primary" @click="toCreateOutline"
          >录入大纲数据</el-button
        >
        <el-button
          type="danger"
          :disabled="multipleSelection.length == 0"
          @click="batchDelete"
          >批量删除</el-button
        >
      </div>
    </div>
    <div class="body-area">
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        border
        stripe
        style="width: 100%;margin:20px 0"
        :header-cell-style="{ background: '#f8f8f9' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          :selectable="isDisabled"
          type="selection"
          align="center"
          width="40"
        />
        <el-table-column
          prop="outlineName"
          label="试验大纲名称"
          min-width="200"
        />
        <el-table-column
          prop="applyUnit"
          label="委托单位名称"
          min-width="200"
        />
        <el-table-column
          prop="applyAddress"
          label="委托单位地址"
          min-width="200"
        />
        <el-table-column
          prop="ratifyDate"
          align="center"
          label="编写日期"
          width="100"
        />
        <el-table-column
          prop="auditStatus"
          label="状态"
          width="100"
          align="center"
        >
          <template slot-scope="{ row }">{{
            row.auditStatus | statusHandle
          }}</template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleClick('edit', scope.row)"
              :disabled="isCanEditOrDelete(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="handleClick('find', scope.row)"
              >查看</el-button
            >

            <el-button type="text" @click="handleClick('log', scope.row)"
              >日志</el-button
            >
            <el-button
              type="text"
              @click="handleClick('audit', scope.row)"
              :disabled="isCanAudit(scope.row)"
              >审核</el-button
            >
            <el-button
              type="text"
              @click="handleClick('carry', scope.row)"
              :disabled="isCanCarry(scope.row)"
              >试验实施</el-button
            >
            <el-button type="text" @click="handleClick('print', scope.row)"
              >打印</el-button
            >
            <el-button
              type="text"
              @click="handleClick('delete', scope.row)"
              :disabled="isCanEditOrDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <Page
        :total="totalNumber"
        @handleCurrentChangeSub="handleCurrentChangeFun"
      />
    </div>
    <!-- 审批历史弹窗 -->
    <LogDialog :businessId="currentBusinessId" ref="LogDialog" />
    <!-- 审批弹窗 -->
    <AuditDialog :businessId="currentBusinessId" ref="AuditDialog" />
  </div>
</template>

<script>
import { outlineList, deleteOutlines } from "@/api/outline";
import LogDialog from "./components/dialog/LogDialog";
import AuditDialog from "./components/dialog/AuditDialog";
import { role, status } from "@/utils/dic";
import { getUserInfo, getRoleInfo } from "@/utils";
export default {
  data() {
    return {
      keywords: "",
      totalNumber: 0,
      tableLoading: true,
      tableData: [],
      multipleSelection: [],
      currentBusinessId: ""
    };
  },
  methods: {
    isDisabled(row) {
      let result = 0;
      if (
        row.auditStatus === status.CAO_GAO ||
        row.auditStatus === status.BO_HUI
      ) {
        if (this.userInfo.id === row.createBy) {
          result = 1;
        }
      }
      return result;
    },
    batchDelete() {
      if (this.multipleSelection.length) {
        this.$confirm("您确定删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: "warning"
        }).then(() => {
          let ids = [];
          this.multipleSelection.forEach(element => {
            ids.push(element.id);
          });
          let idsStr = ids.join(",");
          this.deleteOutlines(idsStr);
        });
      } else {
        this.$message.error("请勾选需要删除的行");
      }
    },
    // 搜索查询列表
    searchList(kind) {
      let params = {};
      if (kind === "search") {
        params.outlineName = this.keywords;
      } else {
        this.keywords = null;
      }
      this.testOutlineList(params);
    },
    handleCurrentChangeFun(page) {
      this.current = page;
      let params = {
        current: page
      };
      this.testOutlineList(params);
    },
    toCreateOutline() {
      this.$router.push({
        name: "outlineDetail"
      });
    },
    testOutlineList(params) {
      outlineList(params)
        .then(res => {
          this.tableLoading = false;
          this.tableData = res.data.records;
          this.totalNumber = res.data.total;
        })
        .catch(err => {
          this.tableLoading = false;
          console.log(err);
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleClick(kind, row) {
      if (kind === "find" || kind === "edit") {
        let params = {
          name: "outlineDetail",
          query: { id: row.id, type: kind }
        };
        this.$router.push(params);
      } else if (kind === "carry") {
        // this.$parent.$parent.$parent.$children[0].defaultIndex = "carry";
        this.$router.push({
          name: "carryCreate",
          query: { outlineId: row.id }
        });
      } else if (kind === "delete") {
        this.$confirm("您确定删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: "warning"
        }).then(() => {
          this.deleteOutlines(row.id);
        });
      } else if (kind === "log") {
        this.currentBusinessId = row.id;
        this.$refs.LogDialog.open();
      } else if (kind === "audit") {
        this.currentBusinessId = row.id;
        this.$refs.AuditDialog.open();
      } else if (kind === "print") {
        let params = {
          name: "printOutline",
          params: { outlineId: row.id }
        };
        this.$router.push(params);
      }
    },
    // 删除大纲数据
    deleteOutlines(id) {
      deleteOutlines(id).then(() => {
        this.checkPage();
        this.$message.success("删除成功");
        this.keywords = "";
        let params = { current: this.current };
        this.testOutlineList(params);
      });
    },
    // 判断跳转
    checkPage() {
      if (
        this.current > 1 &&
        this.tableData.length === this.multipleSelection.length
      ) {
        this.current = this.current - 1;
      }
    }
  },
  created() {
    this.testOutlineList();
    this.userInfo = getUserInfo();
    this.roleInfo = getRoleInfo();
  },
  computed: {
    /**审核权限 */
    isCanAudit() {
      /**
       * 获取角色信息
       */
      return row => {
        let result = false;
        row.auditStatus = parseInt(row.auditStatus);
        /**
         * 草稿、驳回状态且自己创建的不可审核
         */
        /**
         * 校对状态
         */
        if (
          row.auditStatus === status.JIAO_DUI &&
          row.createBy !== this.userInfo.id
        ) {
          if (this.roleInfo.roleId === role.JIAO_DUI) {
            result = true;
          }
        } else if (
          row.auditStatus === status.BIAO_SHEN &&
          row.createBy !== this.userInfo.id
        ) {
          if (this.roleInfo.roleId === role.BIAO_SHEN) {
            result = true;
          }
        } else if (
          row.auditStatus === status.PI_ZHUN &&
          row.createBy !== this.userInfo.id
        ) {
          if (this.roleInfo.roleId === role.PI_ZHUN) {
            result = true;
          }
        }
        return !result;
      };
    },
    /**编辑、删除权限 */
    isCanEditOrDelete() {
      /**
       * 获取用户、角色信息
       * 只有创建者自己并且工单状态是草稿或驳回时才能编辑、删除
       */
      return row => {
        let result = false;
        row.auditStatus = parseInt(row.auditStatus);
        if (
          row.auditStatus === status.CAO_GAO ||
          row.auditStatus === status.BO_HUI
        ) {
          if (this.userInfo.id === row.createBy) {
            result = true;
          }
        }
        return !result;
      };
    },
    /**实施权限 */
    isCanCarry() {
      /**只有已批准的才可以实施 */
      return row => {
        let result = false;
        if (row.auditStatus === status.YI_PI_ZHUN) {
          result = true;
        }
        return !result;
      };
    }
  },
  filters: {
    statusHandle(val) {
      val = parseInt(val);
      let result = "";
      if (val === status.CAO_GAO) {
        result = "草稿";
      } else if (val === status.BO_HUI) {
        result = "已驳回";
      } else if (val === status.JIAO_DUI) {
        result = "校对中";
      } else if (val === status.BIAO_SHEN) {
        result = "标审中";
      } else if (val === status.PI_ZHUN) {
        result = "批准中";
      } else if (val === status.YI_PI_ZHUN) {
        result = "已批准";
      }
      return result;
    }
  },
  components: {
    LogDialog,
    AuditDialog
  }
};
</script>

<style lang="scss" scoped></style>
